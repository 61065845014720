import React, { FC } from 'react';
import { Select, MenuItem, SelectProps } from '@mui/material';
import { makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    input: {
        padding: 0,
    },
}));

interface Option {
    value?: string | number;
    label: string | number;
}
interface SelectCompProps extends SelectProps {
    options: Option[];
    errorText?: string;
    marginBottom?: number;
}
const SelectComp: FC<SelectCompProps> = ({ options, errorText, marginBottom = 15, ...params }) => {
    const classes = useStyles();
    return (
        <div style={{ marginBottom }}>
            <div className="sg-input">
                <Select fullWidth {...params} className={classes.input}>
                    {options.map((o) => (
                        <MenuItem key={o.value} value={o.value}>
                            {o.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <span style={{ color: '#eb3626' }}>{errorText}</span>
        </div>
    );
};

export default SelectComp;
