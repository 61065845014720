import * as Yup from 'yup';

export const createUserSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('User first name is required'),
    lastName: Yup.string().max(255).required('User last name is required'),
    email: Yup.string().email().required('User\'s email is required'),
    companyId: Yup.string(),
    password:Yup.string().required(),
    confirmPassword:Yup.string().oneOf([Yup.ref('password'), null],'Passwords must match').required()
});


export const editUserSchema = Yup.object().shape({
    firstName: Yup.string().max(255).required('User first name is required'),
    lastName: Yup.string().max(255).required('User last name is required'),
    email: Yup.string().email().required('User\'s email is required'),
    companyId: Yup.string(),
    password:Yup.string(),
    confirmPassword:Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
