import React from 'react';
import {
    HomeOutlined as HomeOutlinedIcon,
    NavigationOutlined as NavigationOutlinedIcon,
    Settings as SettingsIcon,
    Receipt as ReportIcon,
} from '@material-ui/icons';

import { DASHBOARD, USERS, DEVICES, TOWERS, GATE_DETAILS, HYDRO_DETAILS, ALERTS, GATE_OPEN_REPORTS } from 'src/constants/routes';
import { RoleName } from './types/Role';
// import { LOCK_ICON, HYDRO_ICON } from 'src/constants/staticPaths';
import LockIcon from 'src/assets/svg/LockIcon';
import HydroIcon from 'src/assets/svg/HydroIcon';

const items = {
    items: [
        {
            id: 'dashboard',
            role: `${RoleName.ADMIN}-${RoleName.USER}-${RoleName.SUPERADMIN}`,
            type: 'group',
            icon: <NavigationOutlinedIcon />,
            children: [
                {
                    id: DASHBOARD,
                    title: 'Dashboard',
                    type: 'item',
                    icon: <HomeOutlinedIcon />,
                    url: DASHBOARD,
                    role: `${RoleName.ADMIN}-${RoleName.USER}-${RoleName.SUPERADMIN}`,
                },
            ],
        },
        {
            id: GATE_DETAILS,
            role: `${RoleName.ADMIN}-${RoleName.USER}-${RoleName.SUPERADMIN}`,
            type: 'group',
            children: [
                {
                    id: GATE_DETAILS,
                    title: 'Gate usage',
                    type: 'item',
                    icon: <LockIcon />,
                    url: GATE_DETAILS,
                    role: `${RoleName.ADMIN}-${RoleName.USER}-${RoleName.SUPERADMIN}`,
                },
            ],
        },
        {
            id: HYDRO_DETAILS,
            role: `${RoleName.ADMIN}-${RoleName.USER}-${RoleName.SUPERADMIN}`,
            type: 'group',
            children: [
                {
                    id: HYDRO_DETAILS,
                    title: 'Hydro usage',
                    type: 'item',
                    icon: <HydroIcon />,
                    url: HYDRO_DETAILS,
                    role: `${RoleName.ADMIN}-${RoleName.USER}-${RoleName.SUPERADMIN}`,
                },
            ],
        },
        {
            id: 'admin',
            type: 'group',
            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,
            children: [
                {
                    id: 'tables',
                    title: 'Manage',
                    type: 'collapse',
                    icon: <SettingsIcon />,
                    role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,

                    children: [
                        {
                            id: DEVICES,
                            title: 'Devices',
                            type: 'item',
                            url: DEVICES,
                            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,
                        },
                        {
                            id: TOWERS,
                            title: 'Towers',
                            type: 'item',
                            url: TOWERS,
                            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,
                        },
                        {
                            id: USERS,
                            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}`,
                            title: 'Users',
                            type: 'item',
                            url: USERS,
                        },
                        {
                            id: ALERTS,
                            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,

                            title: 'Alerts',
                            type: 'item',
                            url: ALERTS,
                        },
                    ],
                },
            ],
        },
        {
            id: 'admin-report',
            type: 'group',
            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,
            children: [
                {
                    id: 'reports',
                    title: 'Reports',
                    type: 'collapse',
                    icon: <ReportIcon />,
                    role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,

                    children: [
                        {
                            id: GATE_OPEN_REPORTS,
                            title: 'Gate Opened Report',
                            type: 'item',
                            url: GATE_OPEN_REPORTS,
                            role: `${RoleName.ADMIN}-${RoleName.SUPERADMIN}-${RoleName.USER}`,
                        },
                    ],
                },
            ],
        },
    ],
};
export default items;
