import React, { FC } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { KeyboardArrowDown } from '@material-ui/icons';

interface Option {
    value: string | number;
    label: string;
}

const AutocompleteCom: FC<{ options: readonly Option[]; onChange?: any; defaultValue?:string }> = ({
    options,
    onChange,
    defaultValue,
}) => {

    const value = options.find((o) => o.value === defaultValue);  
    return (
        <Autocomplete
            options={options}
            onChange={(_event, value) => onChange(value)}
            defaultValue={value}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        style: {
                            padding: 0,
                        },
                    }}
                    variant="outlined"
                    // fullWidth
                />
            )}
            popupIcon={<KeyboardArrowDown />}
        />
    );
};

export default AutocompleteCom;
