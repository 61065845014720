import { useEffect, useState } from 'react';
import { getAllNotificationsConfig } from 'src/services/notificationConfig';
import { NotificationsConfig } from 'src/types/NotificationConfig';

const useNotificationConfig = () => {
    const [notificationConfigs, setNotificationConfigs] = useState<NotificationsConfig[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNotificationConfigs = async () => {
            const { data } = await getAllNotificationsConfig();
            setNotificationConfigs(data);
            setLoading(false);
        };
        fetchNotificationConfigs();
    }, []);

    return {
        loading,
        notificationConfigs,
    };
};

export default useNotificationConfig;
