import React, { useCallback, useContext, useEffect, useState } from 'react';

import { gridSpacing } from 'src/constants/styles';
import GateDetailsForm from 'src/component/GateDetailsForm';
import DashboardLoading from 'src/component/DashboardLoading';
import BarGraph from 'src/component/BarGraph';

import TowersContext from 'src/contexts/towersContext';
import DevicesContext from 'src/contexts/devicesContext';
import { Filter } from 'src/types/filter';
import { getAllMessages } from 'src/services/messages';
import {
    getDevicesTower,
    getEndTimeByFilter,
    getFromTimeByFilter,
    getMaxNumber,
    getStartTimeByFilter,
    getToTimeByFilter,
} from 'src/utilities';

import moment from 'moment';
import { groupBy } from 'lodash';
import Button from '@mui/material/Button';
import DriveFileMoveTwoToneIcon from '@mui/icons-material/DriveFileMoveTwoTone';
import { CSVLink } from 'react-csv';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DeviceTypesEnum } from 'src/types/Device';

const useStyles = makeStyles((theme) => ({
    graph: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
    },
    title: {
        color: theme.palette.text.secondary,
    },
}));

const GateDetails = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('md'));

    const { towers, loading: towerLoading } = useContext(TowersContext);
    const { devices, loading: deviceLoading, getDefaultDeviceGateByTowerId } = useContext(DevicesContext);

    const [filters, setFilters] = useState<Filter>(() => {
        let storedFilter = sessionStorage.getItem('gate-filters') ?? `{}`;
        let { towerId, year, month } = JSON.parse(storedFilter);
        const defaultValues = {
            towerId,
            year,
            month,
            start: getStartTimeByFilter(JSON.parse(storedFilter)),
            end: getEndTimeByFilter(JSON.parse(storedFilter)),
        };
        sessionStorage.setItem('gate-filters', JSON.stringify(defaultValues));
        return defaultValues;
    });

    const [selectedDevices, setSelectedDevices] = useState<string[]>(() => {
        const storedFilter = sessionStorage.getItem('gate-filters') ?? `{}`;
        const storedDevices = sessionStorage.getItem('gate-devices') ?? `[]`;
        let { towerId } = JSON.parse(storedFilter);
        let selectedDevices = JSON.parse(storedDevices);
        if (towerId && !storedDevices.length) {
            const defaultDevice = getDefaultDeviceGateByTowerId(towerId);
            selectedDevices = defaultDevice ? [defaultDevice] : [];
        }

        sessionStorage.setItem('gate-devices', JSON.stringify(selectedDevices));
        return selectedDevices;
    });

    const [data, setData] = useState<Record<string, { date: string; n: number }[]>>({});
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

    const towerDevices = getDevicesTower({ devices, towers, filters, deviceTypeId: DeviceTypesEnum.IOTAGateSensor });
    const from = getFromTimeByFilter(filters);
    const to = getToTimeByFilter(filters);

    const handleChangeForm = (newFilter: Filter) => {
        const storedFilterString = sessionStorage.getItem('gate-filters') ?? `{}`;
        const storedFilter = JSON.parse(storedFilterString);
        let newFilterData = {};
        if (newFilter.start && newFilter.end) {
            newFilterData = { year: undefined, month: undefined };
        } else if (newFilter.month) {
            newFilterData = { year: undefined, start: undefined, end: undefined };
        } else if (newFilter.year) {
            newFilterData = { month: undefined, start: undefined, end: undefined };
        } else if (newFilter.towerId) {
            const defaultDevice = getDefaultDeviceGateByTowerId(newFilter.towerId);
            setSelectedDevices(defaultDevice ? [defaultDevice] : []);
            sessionStorage.setItem('gate-devices', JSON.stringify(defaultDevice ? [defaultDevice] : []));
        }
        sessionStorage.setItem('gate-filters', JSON.stringify({ ...storedFilter, ...newFilter, ...newFilterData }));
        setFilters((f) => ({ ...f, ...newFilter, ...newFilterData }));
    };

    const handleSelectDevice = (deviceId: string) => {
        const isSelected = selectedDevices.some((d) => d === deviceId);
        const newDevices = !isSelected ? [...selectedDevices, deviceId] : selectedDevices.filter((d) => d !== deviceId);
        sessionStorage.setItem('gate-devices', JSON.stringify(newDevices));
        setSelectedDevices([...newDevices]);
    };

    const filterMessages = useCallback(async () => {
        if (!selectedDevices.length || !filters.towerId || !(filters.month || filters.year || (filters.start && filters.end))) {
            setData({});
            return null;
        }
        setIsLocalLoading(true);
        for (const deviceId of selectedDevices) {
            const { data: AllMessages } = await getAllMessages({ from: from.toString(), to: to.toString(), deviceId });
            const diff = moment.unix(+to / 1000).diff(moment.unix(+from / 1000), filters.year ? 'month' : 'day');
            const messages = AllMessages.filter((m: any) => !m.heartBeatOnly && m.data.GateStatus === 'Opened');
            const dataSet = groupBy(messages, (message) => moment.unix(+message.time).format(filters.year ? 'MM/yyyy' : 'DD/MM/yyyy'));
            const finalData = [] as any;
            for (let step = 0; step <= diff; step++) {
                const date = moment
                    .unix(+from / 1000)
                    .add(step, filters.year ? 'month' : 'day')
                    .format(filters.year ? 'MM/yyyy' : 'DD/MM/yyyy');

                finalData.push({
                    date,
                    n: dataSet[date] ? dataSet[date].length : 0,
                });
            }
            setData((data) => ({ ...data, [deviceId]: finalData }));
        }

        setIsLocalLoading(false);
    }, [selectedDevices, filters.towerId, filters.year, filters.month, filters.start, filters.end, from, to]);

    useEffect(() => {
        filterMessages();
    }, [filters, filterMessages, selectedDevices]);

    if (deviceLoading || towerLoading || isLocalLoading) return <DashboardLoading />;

    return (
        <Box p={3} className="gate-details">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <GateDetailsForm
                        towerDevices={towerDevices}
                        onChange={handleChangeForm}
                        showFilter={!!filters.towerId}
                        filters={filters}
                        towers={towers}
                        handleSelectDevice={handleSelectDevice}
                        selectedDevices={selectedDevices}
                    />
                </Grid>
                {selectedDevices.map((selectedDeviceId) => {
                    const selectedDevice = devices.find((d) => d.deviceId === selectedDeviceId);
                    if (!data[selectedDeviceId]) return <></>;
                    const maxNumber = getMaxNumber(data[selectedDeviceId]);

                    return (
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing} style={!isMobile ? { flexDirection: 'column-reverse' } : {}}>
                                <Grid item lg={11} xs={11}>
                                    <Typography
                                        className={classes.title}
                                        variant="h3"
                                        style={{ textTransform: 'capitalize' }}
                                        align="center"
                                    >
                                        {data[selectedDeviceId].length > 0 &&
                                            `${selectedDeviceId} ${selectedDevice?.deviceName} Usage ${moment(from).format(
                                                'MMM DD,YYYY'
                                            )} - ${moment(to).format('MMM DD,YYYY')}`}
                                    </Typography>
                                    <Box className={classes.graph} mt={5}>
                                        {data[selectedDeviceId].length > 0 && (
                                            <BarGraph
                                                ticks={Array.from(Array(maxNumber + 3).keys())}
                                                data={data[selectedDeviceId]}
                                                unit="times"
                                                label="# of times gate opens"
                                            />
                                        )}
                                    </Box>
                                </Grid>
                                <Grid item lg={1} xs={11}>
                                    {data[selectedDeviceId].length > 0 && (
                                        <Button variant="outlined" startIcon={<DriveFileMoveTwoToneIcon />}>
                                            <CSVLink
                                                filename={moment().format('DD/MM/YYYY')}
                                                data={data[selectedDeviceId].map((d) => ({ date: d.date, '# of times gate opens': d.n }))}
                                            >
                                                export
                                            </CSVLink>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </Box>
    );
};

export default GateDetails;
