import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from 'react-loading-skeleton';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: '100%',
        minHeight: '400px',
        overflow: 'hidden',
    },
    loadingHolder: {
        height: '25%',
        width: '100%',
        // background: theme.palette.background.paper,
    },
}));

const DashboardLoading = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid container className={classes.root} justifyContent="space-evenly">
                <Grid item xs={6}>
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                </Grid>
                <Grid item xs={5}>
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                </Grid>
                <Grid item xs={4}>
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                </Grid>
                <Grid item xs={7}>
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                    <Skeleton baseColor="#88888823" className={classes.loadingHolder} />
                </Grid>
            </Grid>
        </div>
    );
};

export default DashboardLoading;
