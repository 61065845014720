import React from 'react'

const LockIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.9262 12C21.9262 17.4821 17.4821 21.9262 12 21.9262C6.51795 21.9262 2.07386 17.4821 2.07386 12C2.07386 6.51795 6.51795 2.07386 12 2.07386C17.4821 2.07386 21.9262 6.51795 21.9262 12ZM23.5991 12C23.5991 18.406 18.406 23.5991 12 23.5991C5.594 23.5991 0.400909 18.406 0.400909 12C0.400909 5.594 5.594 0.400909 12 0.400909C18.406 0.400909 23.5991 5.594 23.5991 12ZM9.41092 9.41093C9.41092 7.98331 10.5724 6.82184 12 6.82184C13.4276 6.82184 14.5891 7.98331 14.5891 9.41093V10.9644H15.1069C15.3816 10.9644 15.645 11.0735 15.8392 11.2677C16.0334 11.4619 16.1425 11.7253 16.1425 12V16.1425C16.1425 16.4172 16.0334 16.6806 15.8392 16.8749C15.645 17.0691 15.3816 17.1782 15.1069 17.1782H8.8931C8.61844 17.1782 8.35502 17.0691 8.1608 16.8749C7.96658 16.6806 7.85747 16.4172 7.85747 16.1425V12C7.85747 11.7253 7.96658 11.4619 8.1608 11.2677C8.35502 11.0735 8.61844 10.9644 8.8931 10.9644H9.41092V9.41093ZM12 7.85748C11.1435 7.85748 10.4466 8.55446 10.4466 9.41093V10.9644H13.5535V9.41093C13.5535 8.55446 12.8565 7.85748 12 7.85748ZM12.5178 16.1425V14.9635C12.6749 14.8734 12.8055 14.7435 12.8964 14.5868C12.9873 14.4302 13.0353 14.2524 13.0356 14.0713C13.0357 13.9162 13.0009 13.7631 12.9338 13.6233C12.8668 13.4834 12.7692 13.3604 12.6483 13.2633C12.5274 13.1662 12.3862 13.0975 12.2352 13.0622C12.0842 13.0269 11.9272 13.026 11.7758 13.0595C11.5719 13.1038 11.3863 13.2088 11.2434 13.3608C11.1004 13.5128 11.0069 13.7045 10.9751 13.9106C10.9433 14.1168 10.9747 14.3278 11.0653 14.5158C11.1558 14.7037 11.3012 14.8598 11.4822 14.9635V16.1425H12.5178Z"
              fill='currentColor'
          />
      </svg>
  );
}

export default LockIcon