import React from 'react';
import { Box } from '@material-ui/core';
import { Typography } from '@mui/material';
import { TOWER } from 'src/constants/staticPaths';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "20%",
        background: theme.palette.grey[400],
        borderRadius: 10,
    },
    towerName: {
        color: '#FFFFFF',
        fontWeight: 500,
        marginLeft: 15,
        fontSize: "1.3rem !important",
    },
}));

const TowerCardHeader = ({ towerName = '' }) => {
    const classes = useStyles();

    return (
        <Box justifyContent="center" className={classes.root} alignItems="center" flexDirection="row" display="flex">
            <img alt="tower" src={TOWER} />
            <Typography className={classes.towerName} >
                {towerName}
            </Typography>
        </Box>
    );
};

export default TowerCardHeader;
