import React from 'react';
import EngineeringIcon from '@mui/icons-material/Engineering';
const ForgetPassword = () => {
    return (
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' , flexDirection:"column" }}>
            <EngineeringIcon style={{color: "#004299" , height:"100px", width:"100px"}}/>
            <p style={{color:"#b81c07", fontSize: "20px" , fontFamily:"fantasy" , fontWeight:700}}>Please contact your admin to reset your password</p>
        </div>
    );
};

export default ForgetPassword;
