import React, { FC } from 'react';
import PublicRoute from 'src/component/PublicRoute';

const MinimalLayout: FC = (props) => {
    const { children } = props;

    return <PublicRoute>{children}</PublicRoute>;
};

export default MinimalLayout;
