import React from 'react';
import { Typography , TypographyProps } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    label: {
        color: `${theme.palette.text.secondary} !important`,
    }
}));
const FormLabelComp = ({ label , style }: { label: string } & TypographyProps) => {
    const classes = useStyles();
    return (
        <Typography className={classes.label} variant="body2" style={{ marginBottom: 10, fontWeight: 'bold', ...style }}>
            {label}
        </Typography>
    );
};

export default FormLabelComp;
