import React, { useContext, FC } from 'react';
import { makeStyles, Typography, ListItem, ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon, ExpandMore, ExpandLess } from '@material-ui/icons';
import NavItem from '../NavItem';
import { useLocation } from 'react-router-dom';
import AuthContext from 'src/contexts/authContext';
import type { Item } from 'src/types/Item';

const useStyles = makeStyles((theme) => ({
    collapseIcon: {
        fontSize: '1rem',
    },
    listIcon: {
        minWidth: '25px',
    },
    listItem: {
        borderRadius: '5px',
        marginBottom: '5px',
    },
    listItemNoBack: {
        backgroundColor: 'transparent !important',
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRadius: '5px',
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
}));

interface NavCollapseProps {
    menu: Item;
    level: number;
}

const NavCollapse: FC<NavCollapseProps> = ({ menu, level }) => {
    const { user } = useContext(AuthContext);

    const classes = useStyles();
    const { pathname } = useLocation();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState('');

    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : '');
    };

    const menus = menu.children
        .filter((item: any) => item.role.includes(user?.role ?? ''))
        .map((item) => {
            switch (item.type) {
                case 'collapse':
                    return <NavCollapse key={item.id} menu={item} level={level + 1} />;
                case 'item':
                    return <NavItem key={item.id} item={item} level={level + 1} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return (
        <React.Fragment>
            <ListItem
                className={level > 1 ? classes.listItemNoBack : classes.listItem}
                selected={pathname === menu.id}
                button
                onClick={handleClick}
                style={{ paddingLeft: level * 16 + 'px' }}
            >
                <ListItemIcon className={!menu.icon ? classes.listIcon : classes.menuIcon}>
                    {menu.icon ?? <ArrowForwardIcon fontSize={level > 0 ? 'inherit' : 'default'} />}
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Typography variant={selected === menu.id ? 'subtitle1' : 'body1'} color="inherit">
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
                {open ? <ExpandLess className={classes.collapseIcon} /> : <ExpandMore className={classes.collapseIcon} />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menus}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

export default NavCollapse;
