import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, FormLabel, TextField, Select } from 'src/component/FormInputs';
import { Form, Formik } from 'formik';
import { createUserSchema, editUserSchema } from 'src/validations/users';
import { newUser } from 'src/types/user';
import { useLocation } from 'react-router-dom';
import useUsers from 'src/hooks/useUser';
import { RoleName } from 'src/types/Role';
import { updateUser, createUser } from 'src/services/users';
import { toast } from 'react-toastify';
import Loader from 'src/component/Loader';
import AuthContext from 'src/contexts/authContext';

const CreateUser = () => {
    const { user: currentUser } = useContext(AuthContext);
    const { state } = useLocation<{ userId?: string }>();
    const { user, loading } = useUsers({ userId: state?.userId, all: false });
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

    const handleCreateUser = async (data: any) => {
        setIsLocalLoading(true);
        try {
            const res = await createUser({ data });
            if (res?.status < 300) {
                toast(`user created successfully`, { type: 'success' });
            } else {
                toast(`couldn't create`, { type: 'error' });
            }
        } catch (error: any) {
            toast(error.response.data.errors, { type: 'error' });
        }

        setIsLocalLoading(false);
    };
    const handleEditUser = async (data: any) => {
        setIsLocalLoading(true);
        try {
            const res = await updateUser({ data: { ...data, userId: data.id } });
            if (res.status < 300) {
                toast(`user updated successfully`, { type: 'success' });
            }
        } catch (error: any) {
            toast(error.response.data.message, { type: 'error' });
        }

        setIsLocalLoading(false);
    };
    return (
        <Box p={2}>
            {isLocalLoading && <Loader />}

            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>
                User
            </Typography>

            {!loading && (
                <Formik
                    initialValues={user ?? newUser}
                    validationSchema={state?.userId ? editUserSchema : createUserSchema}
                    onSubmit={state?.userId ? handleEditUser : handleCreateUser}
                >
                    {({ errors, handleBlur, handleChange, touched, values }) => (
                        <Form>
                            <Box p={2} display="flex" flexDirection="column">
                                <FormLabel label="First Name" />
                                <TextField
                                    name="firstName"
                                    error={Boolean(touched.firstName && errors.firstName)}
                                    errorText={touched.firstName && errors.firstName ? errors.firstName : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                                <FormLabel label="Last Name" />
                                <TextField
                                    name="lastName"
                                    error={Boolean(touched.lastName && errors.lastName)}
                                    errorText={touched.lastName && errors.lastName ? errors.lastName : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                                <FormLabel label="Email" />
                                <TextField
                                    name="email"
                                    error={Boolean(touched.email && errors.email)}
                                    errorText={touched.email && errors.email ? errors.email : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <FormLabel label="Role" />
                                <Select
                                    name="role"
                                    options={Object.keys(RoleName)
                                        .filter((r) => {
                                            if (currentUser?.role === RoleName.ADMIN) {
                                                return r !== RoleName.SUPERADMIN && r !== RoleName.ADMIN;
                                            } else {
                                                return r !== RoleName.SUPERADMIN;
                                            }
                                        })
                                        .map((role) => ({ label: role, value: role }))}
                                    error={Boolean(touched.role && errors.role)}
                                    errorText={touched.role && errors.role ? errors.role : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.role}
                                />
                                <FormLabel label="Password" />
                                <TextField
                                    name="password"
                                    error={Boolean(touched.password && errors.password)}
                                    errorText={touched.password && errors.password ? errors.password : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    type="password"
                                />
                                <FormLabel label="Confirm Password" />
                                <TextField
                                    name="confirmPassword"
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    errorText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    type="password"
                                />
                                <Button text={state?.userId ? 'Edit User' : 'Create user'} type="submit" />
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
        </Box>
    );
};

export default CreateUser;
