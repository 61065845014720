import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import type { Item } from 'src/types/Item';

const useStyles = makeStyles((theme) => ({
    listIcon: {
        minWidth: '25px',        
    },
    listItem: {
        borderRadius: '5px',
        marginBottom: '5px',
    },

    listItemNoBack: {
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRadius: '5px',
        color: theme.palette.text.primary
    },
    errorChip: {
        color: theme.palette.error.main,
        backgroundColor: '#ffcdd2',
        marginRight: '20px',
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
}));
interface NavItemProps {
    item: Item;
    level: number;
}
const NavItem: FC<NavItemProps> = ({ item, level }) => {
    const { pathname } = useLocation();
    const classes = useStyles();
    const itemIcon = item.icon ?? <ArrowForwardIcon color="inherit" fontSize={level > 0 ? 'inherit' : 'default'} />;
    const listItemProps = { component: Link, to: item.url };

    return (
        <ListItem
            className={level > 1 ? classes.listItemNoBack : classes.listItem}
            selected={pathname === item.id}
            button
            style={{ paddingLeft: level * 16 + 'px' }}
            {...listItemProps}
        >
            <ListItemIcon className={!item.icon ? classes.listIcon : classes.menuIcon}>
                {itemIcon}
            </ListItemIcon>
            <ListItemText
                primary={
                    <Typography variant="subtitle1" color="inherit">
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography variant="caption" display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )
                }
            />
        </ListItem>
    );
};

export default NavItem;
