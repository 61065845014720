import * as React from 'react';
import { DataGrid, DataGridProps, GridRowsProp, GridColumns } from '@mui/x-data-grid';
import { Button, TextField } from 'src/component/FormInputs';
import { Search as SearchIcon, KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { gridSpacing } from 'src/constants/styles';
import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    box: {
      minHeight: "100%"  
    },
    table: {
        backgroundColor: `#FFFFFF !important`,
        color: `${theme.palette.text.secondary} !important`,
        minHeight: ' 100%',
    },
    header: {
        color: `${theme.palette.text.primary} !important`,
    },
    cell: {
        color: `${theme.palette.text.primary} !important`,
        '& *': {
            color: `${theme.palette.text.primary} !important`,
        },
    },
}));

const DataTable = ({
    rows = [],
    columns,
    showAdd = false,
    showSearch = true,
    buttonText,
    onAdd,
    onSearch,
}: {
    rows: GridRowsProp;
    columns: GridColumns;
    showAdd?: boolean;
    buttonText?: string;
    showSearch?: boolean;
    onAdd?: () => void;
    onSearch?: (searchValue: string) => void;
}) => {
    const classes = useStyles();

    const options: DataGridProps = {
        columns,
        rows,
        disableColumnMenu: true,
        checkboxSelection: false,
        disableColumnSelector: true,
        disableDensitySelector: true,
        showCellRightBorder: false,
        showColumnRightBorder: false,
        autoHeight: true,
        density: 'comfortable',
        disableSelectionOnClick: true,
        paginationMode: 'client',
        components: {
            ColumnUnsortedIcon: () => <KeyboardArrowDownIcon />,
        },
        className: classes.table,
        rowsPerPageOptions: [25, 50, 100],
        
    };

    return (
        <Box mt={2} className={classes.box}>
            <Grid container spacing={gridSpacing} justifyContent="space-between" alignItems="center">
                {showSearch && (
                    <Grid item lg={6} md={6} xs={12}>
                        <TextField position="end" icon={<SearchIcon />} onChange={(e) => !!onSearch && onSearch(e.target.value)} />
                    </Grid>
                )}
                {showAdd && (
                    <Grid item lg={6} md={6} xs={12}>
                        <Box
                            sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: {
                                    sm: 'flex-end',
                                },
                            }}
                        >
                            <Button text={buttonText} onClick={onAdd} />
                        </Box>
                    </Grid>
                )}
            </Grid>
            <DataGrid {...options} />
        </Box>
    );
};

export default DataTable;
