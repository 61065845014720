import React, { FC } from 'react';
import { Typography, Tooltip } from '@mui/material';
import { BILL } from 'src/constants/staticPaths';
import WarningIcon from '@mui/icons-material/Warning';
import {isNil} from "lodash"
const DeviceInfo: FC<{ deviceName: string; value?: string | number; showPhaseChangeAlarm?: boolean }> = ({
    deviceName,
    value,
    showPhaseChangeAlarm = false,
}) => {
    if (isNil(value)) return null;
    return (
        <div style={{ display: 'flex', width: ' 100%', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', paddingLeft: 20 }}>
                <img alt="bill" src={BILL} style={{ marginRight: 15, width: 30 }} />
                <Typography variant="subtitle1" component="h6">
                    {deviceName}
                </Typography>
            </div>
            {showPhaseChangeAlarm && (
                <Tooltip title="Phase Change" arrow>
                    <WarningIcon color="warning" />
                </Tooltip>
            )}
            <Typography variant="subtitle1" component="h6" style={{ paddingRight: 20 }}>
                {value} KWh
            </Typography>
        </div>
    );
};

export default DeviceInfo;
