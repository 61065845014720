import React, { ReactNode, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { RoleName } from 'src/types/Role';
import Loading from 'src/component/Loader';
import AuthContext from 'src/contexts/authContext';

const AdminRoute = ({ children }: { children: ReactNode }) => {
    const { isAuthenticated, loading, user } = useContext(AuthContext);
    const history = useHistory()

    if (loading) {
        return <Loading />;
    }
    if (!isAuthenticated || !user || user.role === RoleName.USER ) {
        history.push("/login");
        return null
    }
    return <>{children}</>;
};

export default AdminRoute;
