import React, { useContext, useState, useCallback, useEffect } from 'react';

import { gridSpacing } from 'src/constants/styles';

import HydroDetailsForm from 'src/component/HydroDetailsForm';
import BarGraph from 'src/component/BarGraph';
import AreaGraph from 'src/component/AreaGraph';
import DashboardLoading from 'src/component/DashboardLoading';
import {
    getDevicesTower,
    getEndTimeByFilter,
    getFromTimeByFilter,
    getStartTimeByFilter,
    getToTimeByFilter,
} from 'src/utilities';
import TowersContext from 'src/contexts/towersContext';
import DevicesContext from 'src/contexts/devicesContext';

import { getAllMessages } from 'src/services/messages';
import { DeviceTypesEnum } from 'src/types/Device';
import { Filter } from 'src/types/filter';

import moment from 'moment';
import { groupBy } from 'lodash';
import { CSVLink } from 'react-csv';
import { Button, useMediaQuery } from '@mui/material';
import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DriveFileMoveTwoToneIcon from '@mui/icons-material/DriveFileMoveTwoTone';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    graph: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
            width: '95%',
        },
    },
    title: {
        color: theme.palette.text.secondary,
    },
}));

const HydroDetails = () => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('md'));

    const { towers, loading: towerLoading } = useContext(TowersContext);
    const { devices, loading: deviceLoading, getDefaultDeviceHydroByTowerId } = useContext(DevicesContext);

    const [filters, setFilters] = useState<Filter>(() => {
        const storedFilter = sessionStorage.getItem('hydro-filters') ?? `{}`;
        let { towerId, deviceId, year, month } = JSON.parse(storedFilter);
        if (!deviceId && towerId) {
            deviceId = getDefaultDeviceHydroByTowerId(towerId);
        }

        const defaultValues = {
            towerId,
            year,
            month,
            deviceId,
            start: getStartTimeByFilter(JSON.parse(storedFilter)),
            end: getEndTimeByFilter(JSON.parse(storedFilter)),
        };
        sessionStorage.setItem('hydro-filters', JSON.stringify(defaultValues));
        return defaultValues;
    });
    const [data, setData] = useState<{ date: string; n: number; c: number }[]>([]);
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

    const selectedDevice = devices.find((d) => d.deviceId === filters.deviceId);
    const towerDevices = getDevicesTower({ devices, towers, filters, deviceTypeId: DeviceTypesEnum.HCHydroReading });
    const from = getFromTimeByFilter(filters);
    const to = getToTimeByFilter(filters);

    const handleChangeForm = (newFilter: Filter) => {
        const storedFilterString = sessionStorage.getItem('hydro-filters') ?? `{}`;
        const storedFilter = JSON.parse(storedFilterString);
        let newFilterData = {};
        if (newFilter.start && newFilter.end) {
            newFilterData = { year: undefined, month: undefined };
        } else if (newFilter.month) {
            newFilterData = { year: undefined, start: undefined, end: undefined };
        } else if (newFilter.year) {
            newFilterData = { month: undefined, start: undefined, end: undefined };
        } else if (newFilter.towerId) {
            const deviceId = getDefaultDeviceHydroByTowerId(newFilter.towerId);
            newFilterData = { deviceId };
        } else {
        }
        sessionStorage.setItem('hydro-filters', JSON.stringify({ ...storedFilter, ...newFilter, ...newFilterData }));
        setFilters((f) => ({ ...f, ...newFilter, ...newFilterData }));
    };

    const filterMessages = useCallback(async () => {
        if (!filters.towerId || !filters.deviceId || !(filters.month || filters.year || (filters.start && filters.end))) {
            setData([]);
            return null;
        }
        const deviceId = filters.deviceId;
        setIsLocalLoading(true);
        const { data: AllMessages } = await getAllMessages({ from: from.toString(), to: to.toString(), deviceId });
        const messages = AllMessages.filter((m: any) => !!m?.data?.dailyConsumption);
        const diff = moment.unix(+to / 1000).diff(moment.unix(+from / 1000), filters.year ? 'month' : 'day');
        const dataSet = groupBy(messages, (message) => moment.unix(+message.time).format(filters.year ? 'MM/yyyy' : 'DD/MM/yyyy'));
        const finalData = [];
        for (let step = 0; step <= diff; step++) {
            const date = moment
                .unix(+from / 1000)
                .add(step, filters.year ? 'month' : 'day')
                .format(filters.year ? 'MM/yyyy' : 'DD/MM/yyyy');
            if (filters.year) {
                finalData.push({
                    date,
                    n: dataSet[date] ? dataSet[date].reduce((total, curr) => total + +curr?.data?.dailyConsumption?.toFixed(4), 0) : 0,
                    c: dataSet[date]
                        ? dataSet[date].reduce((total, curr) => total + +curr?.data?.dailyConsumptionChange?.toFixed(4), 0)
                        : 0,
                });
            } else {
                finalData.push({
                    date,
                    n: dataSet[date] ? +dataSet[date][0]?.data?.dailyConsumption?.toFixed(4) : 0,
                    c: dataSet[date] ? +dataSet[date][0]?.data?.dailyConsumptionChange?.toFixed(4) : 0,
                });
            }
        }
        setData(finalData);
        setIsLocalLoading(false);
    }, [filters.deviceId, filters.towerId, filters.year, filters.month, filters.start, filters.end, from, to]);

    useEffect(() => {
        filterMessages();
    }, [filters, filterMessages]);

    if (deviceLoading || towerLoading || isLocalLoading) return <DashboardLoading />;

    return (
        <Box p={3} className="gate-details">
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <HydroDetailsForm
                        towerDevices={towerDevices}
                        onChange={handleChangeForm}
                        showFilter={!!filters.towerId}
                        filters={filters}
                        towers={towers}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={12} xs={12}>
                            <Grid container spacing={gridSpacing} style={!isMobile ? { flexDirection: 'column-reverse' } : {}}>
                                <Grid item lg={11} xs={12}>
                                    <Typography
                                        className={classes.title}
                                        variant="h3"
                                        style={{ textTransform: 'capitalize' }}
                                        align="center"
                                    >
                                        {data.length > 0 &&
                                            `${filters.deviceId} Daily Hydro Usage ${moment(from).format('MMM DD,YYYY')} - ${moment(
                                                to
                                            ).format('MMM DD,YYYY')}`}
                                    </Typography>
                                    <Box className={classes.graph} mt={5}>
                                        {data.length > 0 && <BarGraph data={data} unit="kw" label="kilowatt-hour (kWh)" />}
                                    </Box>
                                </Grid>
                                <Grid item lg={1} xs={12}>
                                    {data.length > 0 && (
                                        <Button variant="outlined" startIcon={<DriveFileMoveTwoToneIcon />}>
                                            <CSVLink
                                                filename={moment().format('DD/MM/YYYY')}
                                                data={data.map((d) => ({ date: d.date, 'Hydro reading': d.n }))}
                                            >
                                                export
                                            </CSVLink>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container spacing={gridSpacing} style={!isMobile ? { flexDirection: 'column-reverse' } : {}}>
                                <Grid item lg={11} xs={12}>
                                    <Typography
                                        className={classes.title}
                                        variant="h3"
                                        style={{ textTransform: 'capitalize' }}
                                        align="center"
                                    >
                                        {data.length > 0 &&
                                            `${filters.deviceId} Hydro  ${selectedDevice?.deviceName} Usage Daily Rate of Change  ${moment(
                                                from
                                            ).format('MMM DD,YYYY')} - ${moment(to).format('MMM DD,YYYY')}`}
                                    </Typography>
                                    <Box className={classes.graph} mt={5}>
                                        {data.length > 0 && <AreaGraph data={data} label="Daily usage rate in kilowatt" />}
                                    </Box>
                                </Grid>
                                <Grid item lg={1} xs={12}>
                                    {data.length > 0 && (
                                        <Button variant="outlined" startIcon={<DriveFileMoveTwoToneIcon />}>
                                            <CSVLink
                                                filename={moment().format('DD/MM/YYYY')}
                                                data={data.map((d) => ({ date: d.date, 'Hydro reading': d.c }))}
                                            >
                                                export
                                            </CSVLink>
                                        </Button>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HydroDetails;
