import axios, { AxiosResponse } from 'axios';
import { timeout, baseUrl } from 'src/services/config';

export const getAllTowers = async (): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/towers`);
};


export const updateTower = async ({ data }: any): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).put(`${baseUrl}/towers/${data.towerId}`, data);
};

export const createTower = async ({ data }: any): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).post(`${baseUrl}/towers`, data);
};


export const deleteTower = async (towerId: any): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).delete(`${baseUrl}/towers/${towerId}`);
};
