import axios, { AxiosResponse } from 'axios';
import { timeout, baseUrl } from 'src/services/config';

export const getAllDevices = async (): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/devices`);
};

export const updateDevice = async ({ data }: any): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).put(`${baseUrl}/devices/${data.deviceId}`, data);
};

export const deleteTower = async (ddviceId: any): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).delete(`${baseUrl}/devices/${ddviceId}`);
};
