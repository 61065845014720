import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { NotificationTable } from 'src/component/NotificationTable';
import { gridSpacing } from 'src/constants/styles';
import { TowerSwiper } from 'src/component/TowerSwiper';
import DashboardLoading from 'src/component/DashboardLoading';
import MessagesContext from 'src/contexts/messagesContext';
import Fuse from 'fuse.js';

const Dashboard = () => {
    const { messages, word, loading, filter } = useContext(MessagesContext);
    const searchedMessages = new Fuse(messages, {
        keys: ['towerName'],
    })
        .search(word ?? '')
        .map((res) => res.item);

    const finalMessages = (word ? searchedMessages : messages).filter(
        (message) => !filter || message.devices.some((device) => device.messages.some((m) => m.data?.GateStatus === filter))
    );
    if (loading) return <DashboardLoading />;

    return (
        <Grid container spacing={gridSpacing} style={{height: '100%' }}>
            <Grid item xs={12} style={{ height: '45%'}}>
                <NotificationTable />
            </Grid>

            <Grid item xs={12} style={{ height: '55%'}}>
                <TowerSwiper messages={finalMessages} />
            </Grid>
        </Grid>
    );
};

export default Dashboard;
