import React, { useContext, useState } from 'react';
import {  SETTINGS } from 'src/constants/staticPaths';
import { GridColumns } from '@mui/x-data-grid';
import { Typography, Box } from '@mui/material';
import DataTable from 'src/component/DataTable';
import Tooltip from 'src/component/Tooltip';
import DevicesContext from 'src/contexts/devicesContext';
import { Device, DeviceTypesEnum } from 'src/types/Device';
import TowersContext from 'src/contexts/towersContext';
import { useHistory } from 'react-router';
import DashboardLoading from 'src/component/DashboardLoading';
import Fuse from 'fuse.js';
import { EDIT_DEVICE } from 'src/constants/routes';
import MessagesContext from 'src/contexts/messagesContext';
import { flatten, get } from 'lodash';
import Battery from 'src/assets/svg/Battery';
import { Card } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment';

const Devices = () => {
    const [searchWord, setSearchWord] = useState('');
    const { devices, loading: deviceLoading } = useContext(DevicesContext);
    const { towers, loading: towerLoading } = useContext(TowersContext);
    const { messages, loading: messageLoading } = useContext(MessagesContext);
    const recentMessages = flatten(messages.map((m) => m.devices));

    const history = useHistory();
    const columns: GridColumns = [
        { field: 'index', headerName: '#', renderCell: ({ value }: any) => value + 1 },
        {
            field: 'towerName',
            headerName: 'Site name',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'deviceId',
            headerName: 'Device ID',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'deviceType',
            headerName: 'Device Type',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'deviceName',
            headerName: 'Device Name',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'status',
            width: 100,
            headerName: 'Status',
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ value }: any) => {
                return value?.state === 'SUCCESS' ? (
                    <CheckCircleIcon style={{ cursor: 'pointer', textAlign: 'center', margin: 'auto', color: '#57a96e' }} />
                ) : (
                    <Tooltip
                        title={
                            value?.state === 'WARNING' ? (
                                <Card
                                    style={{
                                        border: 0,
                                        background: '#F1F1F1',
                                        boxShadow: 'none',
                                        borderRadius: 0,
                                        color: '#364861',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    <>
                                        <p style={{ marginTop: 0, color: '#4190ff' }}>
                                            {moment(+value?.time)
                                                .tz('America/Toronto')
                                                .format('MMM DD h:mmA')}
                                        </p>
                                        <p style={{ marginTop: 0 }}>Low battery</p>
                                    </>
                                </Card>
                            ) : undefined
                        }
                    >
                        <ErrorIcon style={{ cursor: 'pointer', textAlign: 'center', margin: 'auto', color: '#ffcb14' }} />
                    </Tooltip>
                );
            },
        },
        {
            field: 'battery_level',
            headerName: 'Battery',
            width: 100,
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ value }: any) => {
                return <Battery level={`${value}`} />;
            },
        },
        {
            field: 'notes',
            headerName: 'Notes',
            width: 100,
            sortable: false,
            renderCell: ({ value }: any) => {
                return <p style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{value}</p>;
            },
        },
        {
            field: 'setting',
            width: 100,
            headerName: 'Settings',
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ value: id }: any) => {
                return (
                    <img
                        alt="gate-status"
                        width={20}
                        src={SETTINGS}
                        onClick={() => handleSelectDevise({ id })}
                        style={{ cursor: 'pointer', textAlign: 'center', margin: 'auto' }}
                    />
                );
            },
        },
    ];

    const rows = devices.map((device: Device, index: number) => {
        let level = undefined;
        const recentMessage = recentMessages.find((message: any) => message?.deviceId === device.deviceId);
        let time = undefined;
        if (device.deviceTypeId === DeviceTypesEnum.HCHydroReading) {
            level = get(recentMessage, 'messages[0].data.battery');
            time = get(recentMessage, 'messages[0].time');
        } else {
            const batteryLevelMessage = recentMessage?.messages.find((m) => m.heartBeatOnly);

            level = get(batteryLevelMessage, 'data.battery');
            time = get(batteryLevelMessage, 'time');
        }
        const { towerName } = towers.find((tower) => tower.towerId === device.towerId) || { towerName: '' };
        const [deviceType] = Object.entries(DeviceTypesEnum).find(([key, value]) => value === device.deviceTypeId) || [];
        if (device.deviceTypeId === DeviceTypesEnum.HCHydroReading) {
            level = +level === 5 ? 'FULL' : level >= 4 ? 'MEDIUM' : 'LOW';
        } else {
            level = +level === 4 ? 'FULL' : level >= 3 ? 'MEDIUM' : 'LOW';
        }
        const status = { time, state: level === 'LOW' ? 'WARNING' : 'SUCCESS' };

        return {
            ...device,
            towerName,
            index,
            status,
            deviceType,
            setting: device.deviceId,
            battery_level: level,
        };
    });
    const filteredRows = searchWord
        ? new Fuse(rows, {
              keys: ['towerName', 'deviceType', 'deviceName', 'deviceId'],
          })
              .search(searchWord)
              .map((res) => res.item)
        : rows;

    const handleSearch = (value: string) => {
        setSearchWord(value);
    };

    const handleSelectDevise = ({ id }: { id: number }) => {
        history.push(EDIT_DEVICE, { deviceId: id });
    };
    if (deviceLoading || towerLoading || messageLoading) return <DashboardLoading />;

    return (
        <Box p={2}>
            <div className="devices-table">
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>
                    Device Management
                </Typography>
                <DataTable rows={filteredRows} columns={columns} onSearch={handleSearch} />
            </div>
        </Box>
    );
};

export default Devices;
