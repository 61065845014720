import React, { FC } from 'react';
import clsx from 'clsx';
import { makeStyles, useMediaQuery, useTheme, AppBar, CssBaseline, Toolbar } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { drawerWidth } from 'src/constants/styles';
import Header from './Header';
import Sidebar from './Sidebar';

const useStyles = (path: string) =>
    makeStyles((theme) => ({
        root: {
            display: 'flex',
            minHeight: '100%',
        },
        appBar: {
            transition: theme.transitions.create('width'),
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
        },
        toolbar: theme.mixins.toolbar,
        content: {
            width: '100%',
            minHeight: '100%',
            flexGrow: 1,
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            [theme.breakpoints.up('md')]: {
                marginLeft: -drawerWidth,
                width: `calc(100% - ${drawerWidth}px)`,
            },
        },
        contentShift: {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        },
        main: {
            padding: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(1),
            },
            backgroundColor: path.includes('dashboard') ? theme.palette.secondary.main : theme.palette.text.primary,
            height: 'calc(100% - 64px)',
        },
        header: {
            zIndex: 1201,
            backgroundColor: theme.palette.text.primary,
        },
    }));

const MainLayout: FC = ({ children }) => {
     const location = useLocation();
    const classes = useStyles(location.pathname)();
    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    React.useEffect(() => {
        setDrawerOpen(matchUpMd);
    }, [matchUpMd]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.header} >
                <Toolbar>
                    <Header drawerToggle={handleDrawerToggle} />
                </Toolbar>
            </AppBar>
            <Sidebar drawerOpen={drawerOpen} drawerToggle={handleDrawerToggle} />
            <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen })}>
                <div className={classes.toolbar} />
                <div className={classes.main}>{children}</div>
            </main>
        </div>
    );
};

export default MainLayout;
