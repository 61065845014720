import React, { useRef, FC } from 'react';
import { Area, Label, XAxis, YAxis, CartesianGrid, ResponsiveContainer, AreaChart } from 'recharts';

const graphHeight = 500;

const BarGraph: FC<{ data: any[]; label?: string }> = ({ data, label = '' }) => {
    const graphRef = useRef<any>(null);

    return (
        <ResponsiveContainer width="100%" height={graphHeight} ref={graphRef}>
            <AreaChart width={730} height={250} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="rgba(54, 72, 97, 0.75)" stopOpacity={0.8} />
                        <stop offset="95%" stopColor="rgba(54, 72, 97, 0.75)" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <XAxis dataKey="date" angle={-45} textAnchor="end" interval={data.length >= 60 ? 5 : 0} height={150} />
                <YAxis interval={0} tickCount={30} stroke="rgba(166, 164, 164, 1)">
                    <Label value={label} position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
                </YAxis>
                <CartesianGrid horizontal vertical={false} stroke="rgba(167, 165, 165, 0.2)" />
                <Area type="linear" dataKey="c" stroke="rgba(54, 72, 97, 1)" fillOpacity={1} fill="url(#colorUv)" />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default BarGraph;
