import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, FormLabel, Switch, TextField } from 'src/component/FormInputs';
import { Grid } from '@material-ui/core';
import useNotificationConfigs from 'src/hooks/useNotificationConfig';
import { NotificationsConfig, NotificationsConfigStatusEnum, NotificationsConfigTypesEnum } from 'src/types/NotificationConfig';
import { updateNotificationsConfig } from 'src/services/notificationConfig';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import DashboardLoading from 'src/component/DashboardLoading';
import Loader from 'src/component/Loader';
import moment from 'moment';

const LABELS_MAP = {
    [NotificationsConfigTypesEnum.GateClosed]: 'A gate was locked between the special time period.',
    [NotificationsConfigTypesEnum.GateOpens]: 'A gate was unlocked between the special time period.',
    [NotificationsConfigTypesEnum.GateLeftOpened]: 'A gate is unlocked for more than the designated time period.',
    [NotificationsConfigTypesEnum.HydroDrop]: 'A hydro meter experiences a voltage drop.',
    [NotificationsConfigTypesEnum.DeviceError]: 'A device requires troubleshooting.',
    [NotificationsConfigTypesEnum.LowBattery]: 'A device is low battery.',
};

const useStyles = makeStyles((theme) => ({
    alertRoot: {
        color: theme.palette.text.secondary,
    },
    inputMask: {
        background: 'transparent',
        border: '1px solid #a6a4a4 !important',
        borderRadius: 4,
        letterSpacing: 3,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 8,
    },
    error: {
        color: '#cc0e04',
        fontSize: 12,
        fontWeight: 600,
    },
}));
const Alerts = () => {
    const classes = useStyles();
    const { notificationConfigs, loading } = useNotificationConfigs();
    const [configs, setConfigs] = useState<NotificationsConfig[]>([]);
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [hours, setHours] = useState<number | undefined>(0);
    const [timeRange, setTimeRange] = useState<{ from: string; to: string }>({ from: ``, to: `` });

    const handleUpdateNotificationConfig = async () => {
        if ((!!timeRange.from || !!timeRange.to)) {
            if (!timeRange.from || !timeRange.to || !moment(timeRange.from, 'hh:mm').isBefore(moment(timeRange.to, 'hh:mm'))) {
                return setHasError(true);
            }
       }
        setHasError(false);
        const finalConfigs = configs
            .map((config) => (config.notificationType === NotificationsConfigTypesEnum.GateOpens ? { ...config, timeRange } : config))
            .map((config) =>
                config.notificationType === NotificationsConfigTypesEnum.GateLeftOpened ? { ...config, threshold: hours } : config
            );
        setIsLocalLoading(true);
        const configsPromises = finalConfigs.map((config) => updateNotificationsConfig(config));
        await Promise.all(configsPromises);
        setIsLocalLoading(false);
        toast(`configuration updated successfully`, { type: 'success' });
    };

    const handleChangeHours = (event: any) => {
        setHours(event.target.value);
    };

    const changeNotification = (
        status: NotificationsConfigStatusEnum,
        type: NotificationsConfigTypesEnum,
        method: 'email' | 'dashboard' = 'dashboard'
    ) => {
        if (method === 'dashboard' && !status) {
            const newConfig = configs.map((m) => (m.notificationType === type ? { ...m, status: 0, isEmailOn: false } : m));
            setConfigs(newConfig);
        }
        if (method === 'email' && status) {
            const newConfig = configs.map((m) => (m.notificationType === type ? { ...m, status: 1, isEmailOn: true } : m));
            setConfigs(newConfig);
        }
        if (method === 'dashboard' && status) {
            const newConfig = configs.map((m) => (m.notificationType === type ? { ...m, status: 1 } : m));
            setConfigs(newConfig);
        }

        if (method === 'email' && !status) {
            const newConfig = configs.map((m) => (m.notificationType === type ? { ...m, isEmailOn: false } : m));
            setConfigs(newConfig);
        }
    };

    useEffect(() => {
        if (!loading) {
            if (notificationConfigs.length) {
                setConfigs(notificationConfigs);
                const defaultHours = notificationConfigs.find(
                    (config) => config.notificationType === NotificationsConfigTypesEnum.GateLeftOpened
                )?.threshold;
                const defaultTimeRange = notificationConfigs.find(
                    (config) => config.notificationType === NotificationsConfigTypesEnum.GateOpens
                )?.timeRange;
                setHours(defaultHours);
                setTimeRange((t) => ({ ...t, ...defaultTimeRange }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    if (loading) return <DashboardLoading />;

    return (
        <>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>
                Manage Alerts
            </Typography>

            <div className={classes.alertRoot}>
                {isLocalLoading && <Loader />}
                <Grid item xs={12} lg={5}>
                    <FormLabel label="Customize alerts:" />
                    <Grid container>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Grid container spacing={1}>
                                <Grid item xs={8}>
                                    <span>Alert when a gate is unlocked or locked for more a designated time period of</span>
                                </Grid>
                                <Grid item xs={4} md={2}>
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <TextField value={hours} onChange={handleChangeHours} marginBottom={0} />
                                        <span style={{ marginTop: '5px', marginLeft: '5px' }}>Hours</span>
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: 10 }}>
                            <Grid container>
                                <Grid item lg={8} xs={12}>
                                    <span>Alert when a gate is unlocked between special time period of</span>
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <div className="custom-time-input">
                                        <TextField
                                            type="time"
                                            icon=""
                                            style={{ width: 'min-content', border: 'none', borderBottomWidth: 0, marginBottom: 0 }}
                                            onChange={(e) => setTimeRange({ ...timeRange, from: e.target.value })}
                                            value={timeRange?.from}
                                            marginBottom={0}
                                        />
                                        <span> -</span>
                                        <TextField
                                            type="time"
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                            icon=""
                                            style={{ width: 'min-content', border: 'none', borderBottomWidth: 0, marginBottom: 0 }}
                                            onChange={(e) => setTimeRange({ ...timeRange, to: e.target.value })}
                                            marginBottom={0}
                                            value={timeRange?.to}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {hasError && <span className={classes.error}>The start of your time range should be before the end!</span>}
                <Grid item xs={12} lg={5}>
                    <Box p={2} display="flex" flexDirection="column">
                        <Grid container>
                            <Grid item xs={8}>
                                <FormLabel label="Send me an notification when:" />
                            </Grid>
                            <Grid item xs={2}>
                                <FormLabel label="Dashboard" />
                            </Grid>
                            <Grid item xs={2}>
                                <FormLabel label="Email" />
                            </Grid>
                        </Grid>
                        {configs.map((notificationConfig) => (
                            <Grid container key={notificationConfig.id}>
                                <Grid item xs={8}>
                                    <p style={{ fontSize: 16, fontWeight: 400, marginTop: 5 }}>
                                        {LABELS_MAP[notificationConfig.notificationType]}
                                    </p>
                                </Grid>

                                <Grid item xs={2}>
                                    <Switch
                                        checked={notificationConfig.status === NotificationsConfigStatusEnum.ON}
                                        onChange={(e) =>
                                            changeNotification(
                                                e.target.checked ? NotificationsConfigStatusEnum.ON : NotificationsConfigStatusEnum.OFF,
                                                notificationConfig.notificationType,
                                                'dashboard'
                                            )
                                        }
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <Switch
                                        checked={!!notificationConfig.isEmailOn}
                                        onChange={(e) =>
                                            changeNotification(
                                                e.target.checked ? NotificationsConfigStatusEnum.ON : NotificationsConfigStatusEnum.OFF,
                                                notificationConfig.notificationType,
                                                'email'
                                            )
                                        }
                                    />
                                </Grid>
                            </Grid>
                        ))}
                        <Button text="Submit" onClick={handleUpdateNotificationConfig} />
                    </Box>
                </Grid>
            </div>
        </>
    );
};

export default Alerts;
