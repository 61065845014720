export enum NotificationsConfigStatusEnum {
    OFF = 0,
    ON = 1
}

export enum NotificationsConfigTypesEnum {
    GateOpens,
    LowBattery,
    DeviceError,
    HydroDrop,
    GateLeftOpened,
    GateClosed,
};

export interface NotificationsConfig {
    id?: string;
    notificationConfigId: string;
    notificationType: NotificationsConfigTypesEnum;
    status: NotificationsConfigStatusEnum;
    modifiedAt: string;
    threshold?: number;
    timeRange?: Record<string, string>;
    userId: string;
    isEmailOn?: boolean;
}