export const LOGO = './images/logo.svg';
export const LOCKED = './images/locked.svg';
export const UNLOCKED = './images/unlocked.svg';
export const TOWER = './images/tower.svg';
export const BILL = './images/bill.svg';
export const STATUS_OK = './images/statusOk.svg';
export const STATUS_NOT_OK = './images/statusNotOK.svg';
export const DISABLE_LOCK = './images/disable-lock.svg';
export const FULL_BATTERY = './images/battery.svg';
export const SETTINGS = './images/setting.svg';
export const LOGO_ONLY = './images/logoOnly.ico';

