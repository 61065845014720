import React, { useContext } from 'react';

import { List, Typography } from '@material-ui/core';
import AuthContext from 'src/contexts/authContext';

import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

const NavGroup = ({ item }: { item: any }) => {
    const { user } = useContext(AuthContext);

    const items = item.children.filter((item: any) => item.role.includes(user?.role ?? ''))
        .filter((item: any) => item.role.includes(user?.role ?? ''))
        .map((menu: any) => {
            switch (menu.type) {
                case 'collapse':
                    return <NavCollapse key={menu.id} menu={menu} level={1} />;
                case 'item':
                    return <NavItem key={menu.id} item={menu} level={1} />;
                default:
                    return (
                        <Typography key={menu.id} variant="h6" color="error" align="center">
                            Menu Items Error
                        </Typography>
                    );
            }
        });

    return (
        <List
            subheader={
                <Typography variant="caption" display="block" gutterBottom>
                    {item.title}
                    {item.caption && (
                        <Typography variant="caption" display="block" gutterBottom>
                            {item.caption}
                        </Typography>
                    )}
                </Typography>
            }
        >
            {items}
        </List>
    );
};

export default NavGroup;
