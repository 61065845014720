import React, { useContext } from 'react';
import { Card, CardContent, Typography, makeStyles, Grid, TextField, Button, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import AuthContext from 'src/contexts/authContext';
import { LOGO } from 'src/constants/staticPaths';
import ErrorDialog from 'src/component/ErrorDialog/ErrorDialog';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        height: '100vh',
        minHeight: '100%',
    },
    backButton: {
        marginLeft: theme.spacing(2),
    },
    card: {
        backgroundColor: theme.palette.text.primary,
        overflow: 'visible',
        margin: '10px auto',
    },
    cardWrapper: {
        maxWidth: 500,
    },
    content: {
        padding: theme.spacing(5, 4, 3, 4),
    },
    inputField: {
        '& > *': {
            color: `${theme.palette.text.secondary} !important`,
        },
        boxShadow: `0px 4px 10px 0px ${theme.palette.secondary.main}`,
        marginRight: '3%',
    },
    forgot: {
        color: theme.palette.text.secondary,
        textDecoration: 'none',
        fontSize: '.75rem',
    },
    login: {
        textDecoration: 'none',
        width: '100%',
    },
    margin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    logo: {
        height: 150,
    },
}));

const Login = () => {
    const [error, setError] = React.useState<any>();
    const classes = useStyles();
    const { login, loading } = useContext(AuthContext);
    const handleLogin = async (e: any) => {
        e.preventDefault();
        const { success, message } = await login({ email: e.target.email.value, password: e.target.password.value });
        if (!success) {
            setError(message);
        }
    };

    return (
        <>
            {error && <ErrorDialog message={error} />}
            <Grid container justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item xs={11} sm={7} md={5} lg={3} className={classes.cardWrapper}>
                    <Grid container justifyContent="center">
                        <img alt="Auth method" src={LOGO} className={classes.logo} />
                        <Typography color="textSecondary" gutterBottom variant="h3" align="center">
                            TOWER MONITORING DASHBOARD
                        </Typography>
                    </Grid>
                    <Card className={classes.card}>
                        <CardContent className={classes.content}>
                            <form onSubmit={handleLogin}>
                                <Grid container spacing={2} justifyContent="center" alignContent="center">
                                    <Grid item xs={11}>
                                        <TextField
                                            fullWidth
                                            label="Email Address / Username"
                                            margin="normal"
                                            name="email"
                                            type="email"
                                            variant="outlined"
                                            className={classes.inputField}
                                        />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            margin="normal"
                                            name="password"
                                            type="password"
                                            variant="outlined"
                                            className={classes.inputField}
                                        />
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Button type="submit" disabled={loading} className={classes.login} variant="contained">
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    style={{
                                                        color: 'red',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                            Login
                                        </Button>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <Grid container justifyContent="center" alignItems="center" className={classes.margin}>
                                            <Grid item xs={6}>
                                                <Typography variant="subtitle2" color="secondary" className={classes.forgot}>
                                                    <input type="checkbox" className={classes.inputField} />
                                                    Remember Me
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                item
                                                xs={6}
                                                style={{
                                                    textAlign: 'right',
                                                }}
                                            >
                                                <Typography
                                                    variant="subtitle2"
                                                    color="secondary"
                                                    className={classes.forgot}
                                                    component={Link}
                                                    to="/forgotPassword"
                                                >
                                                    Forgot password?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default Login;
