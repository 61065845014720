import axios from 'axios';
import { timeout, baseUrl } from 'src/services/config';

export const getReports = async ({ towerId, limit }: { towerId: string; limit?: number }) => {
    const params = {
        towerId,
        ...(limit && { limit: limit.toString() }),
    };
    const finalParams = new URLSearchParams(params).toString();

    try {
        const { data } = await axios
            .create({ timeout, withCredentials: true })
            .get(`${baseUrl}/reports/closedGate/${towerId}?${finalParams}`);
        return data;
    } catch (err) {
        console.log(err);
        throw err;
    }
};
