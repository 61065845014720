import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { gridSpacing } from 'src/constants/styles';
import { Typography, Button, Stack } from '@mui/material';
import { FormLabel, AutoComplete, Select, DateRange } from 'src/component/FormInputs';
import { Tower } from 'src/types/Tower';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Device } from 'src/types/Device';
import HydroIcon from 'src/assets/svg/HydroIcon';
import { Filter } from 'src/types/filter';
import { getAllMonthsForYear } from 'src/utilities';


const useStyles = makeStyles((theme) => ({
    deviceButtonSelected: {
        '&:hover': {
            borderWidth: 2,
            color: theme.palette.primary.main,
        },
    },
    deviceButtonNotSelected: {
        color: '#a6a4a4 !important',
        border: '2px solid #a6a4a4 !important',
        '&:hover': {
            border: '2px solid #a6a4a4 !important',
        },
    },
    title: {
        color: theme.palette.text.secondary,
    },
}));

const HydroDetailsForm: FC<{
    towerDevices: Device[];
    onChange: (newFilter: Record<string, any>) => void;
    showFilter?: boolean;
    filters: Filter;
    towers: Tower[];
}> = ({ towerDevices, onChange, showFilter = false, filters, towers }) => {
    const classes = useStyles();
    const months = getAllMonthsForYear()
    const diff = +moment().diff('2022-01-01', 'years') + 1;
    const years = Array.from(Array(diff).keys()).map((i: number) => i + moment().year());
    const selectedTower = towers.find(tower => tower.towerId  === filters.towerId)
    return (
        <>
            <Grid container spacing={gridSpacing} justifyContent="space-between">
                <Grid container xs={12} justifyContent="space-between">
                    <Grid item lg={4} md={6} xs={12}>
                        <FormLabel label="Site Name" />
                        <AutoComplete
                            defaultValue={filters.towerId}
                            options={towers.map((t) => ({ label: t.towerName, value: t.towerId }))}
                            onChange={({ value }: any) => onChange({ towerId: value })}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} xs={12}>
                        {selectedTower && selectedTower.powerSource && (
                            <>
                                <FormLabel label="Power Source" />
                                <Typography
                                    className={classes.title}
                                    variant="body2"
                                    style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
                                >
                                    {selectedTower.powerSource}
                                </Typography>
                            </>
                        )}
                    </Grid>
                </Grid>

                <Grid item lg={7} md={6} xs={12}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item lg={3} md={6} xs={12}>
                            <FormLabel label="Monthly Usage" />
                            <Select
                                options={[
                                    { label: 'select month', value: undefined },
                                    ...months.map((m, i) => ({ label: m, value: i + 1 })),
                                ]}
                                onChange={(event: any) => onChange({ month: event.target.value })}
                                value={filters.month}
                            />
                        </Grid>
                        <Grid item lg={3} md={6} xs={12}>
                            <FormLabel label="Annual Usage" />
                            <Select
                                options={[{ label: 'select year', value: undefined }, ...years.map((m, i) => ({ label: m, value: m }))]}
                                value={filters.year}
                                onChange={(event: any) => onChange({ year: event.target.value })}
                            />
                        </Grid>
                        <Grid item lg={5} md={12} xs={12}>
                            <FormLabel label="Date Range" />
                            <DateRange onChange={onChange} dateRangeFilter={{ start: filters.start, end: filters.end }} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {showFilter && (
                <Box mt={2}>
                    <FormLabel label="Select Meter" />

                    <Stack direction="row" spacing={2}>
                        {towerDevices.map((device) => (
                            <Button
                                className={
                                    device.deviceId !== filters.deviceId ? classes.deviceButtonNotSelected : classes.deviceButtonSelected
                                }
                                variant="outlined"
                                startIcon={<HydroIcon />}
                                size="large"
                                onClick={(_event: any) => onChange({ deviceId: device.deviceId })}
                                key={device.deviceId}
                            >
                                <Typography variant="body2" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                    {device.deviceName}
                                </Typography>
                            </Button>
                        ))}
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default HydroDetailsForm;
