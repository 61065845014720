import { createTheme } from '@material-ui/core/styles';
import value from './palette';
import grey from '@material-ui/core/colors/grey';

export function theme(mode: 'dark' | 'light' | 'gray') {
    let primary;
    let secondary;
    let background;
    let textPrimary;
    let textSecondary;
    let textHelper;

    let textDark;
    let textHint;
    let textInversePrimary;

    switch (mode) {
        case 'dark':
            primary = value.primaryDark;
            secondary = value.secondaryDark;
            background = value.backgroundDark;
            textPrimary = value.textPrimaryDark;
            textSecondary = value.textSecondaryDark;
            textHelper = value.textHelperDark;
            break;
        case 'gray':
            primary = value.primaryGray;
            secondary = value.secondaryGray;
            background = value.backgroundGray;
            textPrimary = value.textPrimaryGray;
            textSecondary = value.textSecondaryGray;
            textHelper = value.textHelperGray;

            break;
        case 'light':
        default:
            primary = value.primaryLight;
            secondary = value.secondaryLight;
            background = value.backgroundLight;
            textPrimary = value.textPrimaryLight;
            textSecondary = value.textSecondaryLight;
            textHelper = value.textHelperLight;

            break;
    }

    return createTheme({
        palette: {
            common: {
                black: value.paperDark,
            },
            primary: {
                main: primary,
            },
            secondary: {
                main: secondary,
            },
            error: {
                light: value.errorLight,
                main: value.error,
                dark: value.errorDark,
            },
            warning: {
                light: value.warningLight,
                main: value.warning,
                dark: value.warningDark,
            },
            info: {
                light: value.infoLight,
                main: value.info,
                dark: value.infoDark,
            },
            success: {
                light: value.successLight,
                main: value.success,
                dark: value.successDark,
            },
            grey: {
                300: value.grey300,
                400: value.grey400,
            },
            text: {
                primary: textPrimary,
                secondary: textSecondary,
                hint: textHelper,
            },
            background: {
                paper: background,
                default: background,
            },
        },
        typography: {
            fontFamily: `'Poppins', sans-serif`,
            h6: {
                fontWeight: 600,
                color: textSecondary,
                fontSize: '0.875rem',
            },
            h5: {
                fontSize: '1.125rem',
                color: textSecondary,
                fontWeight: 600,
            },
            h4: {
                fontSize: '1.25rem',
                color: textSecondary,
                fontWeight: 500,
            },
            h3: {
                fontSize: '1.5rem',
                color: textDark,
                fontWeight: 600,
            },
            h2: {
                fontSize: '2rem',
                color: textDark,
                fontWeight: 600,
            },
            h1: {
                fontSize: '2.2rem',
                color: textDark,
                fontWeight: 600,
            },
            subtitle1: {
                fontSize: '0.875rem',
                fontWeight: 500,
                color: textSecondary,
                lineHeight: '1.643em',
            },
            subtitle2: {
                fontSize: '0.8125rem',
                fontWeight: 400,
            },
            caption: {
                fontSize: '0.68rem',
                color: textHint,
                fontWeight: 500,
            },
            body1: {
                fontSize: '0.875rem',
                fontWeight: 400,
                lineHeight: '1.643em',
            },
            body2: {
                letterSpacing: '0em',
                fontWeight: 400,
                lineHeight: '1.643em',
            },
        },
        overrides: {
            MuiAccordion: {
                root: {
                    boxShadow: 'none',
                },
            },
            MuiAccordionSummary: {
                root: {
                    fontWeight: 600,
                    fontSize: '0.875rem',
                },
                content: {
                    color: textSecondary,
                    fontWeight: 500,
                },
            },
            MuiPaper: {
                elevation1: {
                    boxShadow: '0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%)',
                },
                rounded: {
                    borderRadius: '10px',
                },
            },
            MuiCard: {
                root: {
                    // border:'1px solid rgba(33, 40, 50, 0.125)'
                },
            },
            MuiCardHeader: {
                root: {
                    color: textDark,
                    padding: '24px',
                    //backgroundColor: headerBackColor,
                },
            },
            MuiCardContent: {
                root: {
                    padding: '24px',
                },
            },
            MuiCardActions: {
                root: {
                    padding: '24px',
                },
            },
            MuiSvgIcon: {
                root: {
                    fontSize: '1.3rem',
                },
            },
            // Table
            MuiTableCell: {
                root: {
                    padding: '16px 36px 16px 36px',
                    whiteSpace: 'nowrap',
                },
                head: {
                    padding: '16px 36px 16px 36px',
                    color: textDark,
                    fontWeight: 600,
                },
                paddingCheckbox: {
                    paddingLeft: '36px',
                    position: 'relative',
                },
            },
            MuiList: {
                root: {
                    overflow: 'hidden',
                },
            },
            MuiListItem: {
                root: {
                    color: textInversePrimary,
                    paddingTop: '12px',
                    paddingBottom: '12px',
                    '&$selected': {
                        color: mode === 'dark' ? value.menuHover : primary,
                        backgroundColor: mode !== 'dark' ? value.menuHover : primary,
                        '&:hover': {
                            backgroundColor: mode !== 'dark' ? value.menuHover : primary,
                        },
                        '& .MuiListItemIcon-root': {
                            color: mode === 'dark' ? value.menuHover : primary,
                        },
                    },
                    '&:hover': {
                        color: mode === 'dark' ? value.menuHover : primary,
                        '& .MuiListItemIcon-root': {
                            color: mode === 'dark' ? value.menuHover : primary,
                        },
                    },
                },
                button: {
                    '&:hover': {
                        backgroundColor: mode !== 'dark' ? value.menuHover : primary,
                    },
                },
            },
            MuiListItemIcon: {
                root: {
                    minWidth: '36px',
                    color: textInversePrimary,
                },
            },
            MuiTableHead: {
                root: {
                    background: background,
                    //background: '#fbfdfe'
                },
            },
            MuiChip: {
                colorSecondary: {
                    color: grey[100],
                },
                colorPrimary: {
                    color: grey[100],
                },
                root: {
                    color: grey[100],
                },
                outlined: {
                    color: grey[500],
                },
            },
            MuiTableContainer: {
                root: {
                    boxShadow: 'none',
                },
            },
            MuiAvatar: {
                colorDefault: {
                    backgroundColor: value.textHint,
                    color: grey[100],
                },
            },
            MuiInputBase: {
                input: {
                    color: textDark,
                },
            },
            MuiSelect: {
                select: {
                    paddingBottom: 8,
                    paddingTop: 150,
                },
            },
        },
    });
}

export default theme;
