const pallette = {
    // primary Color
    primaryDark: '#0043a9',
    primaryLight: '#3380f4',
    primaryGray: '#364861',

    // secondary Color
    secondaryDark: '#272f33',
    secondaryLight: '#60696d',
    secondaryGray: '#e9edf2',

    // Background Color
    backgroundDark: '#132f4c',
    backgroundLight: '#FFFFFF',
    backgroundGray: '#364861',

    // text Primary Color
    textPrimaryDark: '#FFFFFF',
    textPrimaryLight: '#132f4c',
    textPrimaryGray: '#FFFFFF',

    // text Secondary Color
    textSecondaryDark: '#FFFFFF',
    textSecondaryLight: '#132f4c',
    textSecondaryGray: '#364861',

    // helper text Color
    textHelperDark: '#A6A4A4',
    textHelperLight: '#A6A4A4',
    textHelperGray: '#A6A4A4',

    error: '#ff413a',
    errorDark: '#a20e00',
    errorLight: '#ec4333',
    errorGray: '#DE6969',

    warning: '#f4a100',
    warningDark: '#aa7000',
    warningLight: '#f6b333',
    warningGray: '#FFCB14',

    info: '#00cfd5',
    infoDark: '#009095',
    infoLight: '#33d8dd',
    infoGray: '#A6A4A4',

    success: '#00ac69',
    successDark: '#007849',
    successLight: '#33bc87',
    successGray: '#57A96E',

    grey300: '#425466',
    grey400: '#425466',
    dark: '#343a40',

    bg100: '#f8f8f9',

    textHint: '#00000061',

    // Paper Color
    paper: '#ffffff',
    paperDark: '#232b38',

    menuHover: '#e3f2fd',
};

export default pallette;
