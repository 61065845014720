import React, { createContext, FC, useState } from 'react';
type Theme = 'gray' | 'light' | 'dark';
const ThemeContext = createContext<{
    theme: Theme;
    changeTheme: () => void;
}>({
    theme: 'gray',
    changeTheme: () => {},
});
export const ThemeContextProvider: FC = ({ children }) => {
    const [currentTheme, setCurrentTheme] = useState<'light' | 'dark' | 'gray'>('gray');
    const themeArray: Theme[] = ['light', 'dark', 'gray'];
    const changeTheme = () => {
        const index = (themeArray.findIndex((f) => f === currentTheme) + 1) % 3;
        setCurrentTheme(themeArray[index]);
    };
    return <ThemeContext.Provider value={{ theme: currentTheme, changeTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeContext;
