import React, { FC } from 'react';
import { TowerCard } from 'src/component/TowerCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Keyboard } from 'swiper';
import { MessageByTower } from 'src/types/Message';

const TowerSwiper: FC<{ messages: MessageByTower[] }> = ({ messages }) => {
    return (
        <div style={{ position: 'relative' , padding:"0 33px" , maxHeight: '100%', height: '100%' }}>
            <div id="swiper-button-prev" className="swiper-button-prev" style={{ position: 'absolute' }} />
            <Swiper
                spaceBetween={30}
                breakpoints={{
                    250: {
                        slidesPerView: 1,
                    },
                    768: {
                        slidesPerView: 2,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                }}
                keyboard
                observer
                navigation={{
                    nextEl: '#swiper-button-next',
                    prevEl: '#swiper-button-prev',
                }}
                modules={[Keyboard, Navigation]}

                                    style={{  height: '100%' }}
            >
                {messages.map((message) => (
                    <SwiperSlide key={message.id} style={{ height: '100%' }}>
                        <TowerCard message={message} key={message.towerId} />
                    </SwiperSlide>
                ))}
     
            </Swiper>
            <div id="swiper-button-next" className="swiper-button-next" style={{ position: 'absolute' }} />
        </div>
    );
};

export default TowerSwiper;
