import React, { useContext } from 'react';
import { makeStyles, Fade, Button, ClickAwayListener, Paper, Popper, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
    PersonTwoTone as PersonTwoToneIcon,
    AccountCircleTwoTone as AccountCircleTwoToneIcon,
    MeetingRoomTwoTone as MeetingRoomTwoToneIcon,
} from '@material-ui/icons';
import AuthContext from 'src/contexts/authContext';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: '350px',
        minWidth: '250px',
        backgroundColor: theme.palette.background.default,
        paddingBottom: 0,
        borderRadius: '10px',
    },
    menuIcon: {
        fontSize: '1.5rem',
    },
    menuButton: {
        [theme.breakpoints.down('sm')]: {
            minWidth: '50px',
        },
        [theme.breakpoints.down('xs')]: {
            minWidth: '35px',
        },
        color: theme.palette.text.secondary,
    },
    text: {
        color: theme.palette.text.secondary,
    },
}));

const links = [
    {
        link: '/profile',
        text: 'Profile',
        icon: <PersonTwoToneIcon />,
    },
];

const ProfileSection = () => {
    const classes = useStyles();
    const { logout } = useContext(AuthContext)
    const history = useHistory()
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<any>();

    const handleLogout = async () => {
        try {
            await logout()
            history.push("/login")
        } catch (error) {
            console.log(error)
        }
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Button
                className={classes.menuButton}
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            
            >
                <AccountCircleTwoToneIcon className={classes.menuIcon} />
            </Button>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: {
                        offset: {
                            enable: true,
                            offset: '0px, 10px',
                        },
                        preventOverflow: {
                            padding: 0,
                        },
                    },
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <List component="nav" className={classes.root}>
                                    {links.map((link, index) => {
                                        return (
                                            <ListItem key={link.link} button component={Link} to={link.link}>
                                                <ListItemIcon>{link.icon}</ListItemIcon>
                                                <ListItemText primary={link.text} />
                                            </ListItem>
                                        );
                                    })}

                                    <ListItem button onClick={handleLogout}>
                                        <ListItemIcon>
                                            <MeetingRoomTwoToneIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Logout" />
                                    </ListItem>
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default ProfileSection;
