import React, { FC, useContext, useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Button, FormLabel, TextField, Select, Switch } from 'src/component/FormInputs';
import { Form, Formik } from 'formik';
import { editDeviceSchema } from 'src/validations/device';
import { DeviceData, DeviceTypesEnum, DeviceTypesNameEnum } from 'src/types/Device';
import { useLocation } from 'react-router';
import DevicesContext from 'src/contexts/devicesContext';
import TowersContext from 'src/contexts/towersContext';
import { updateDevice, deleteTower as deleteOneDevice } from 'src/services/devices';
import DashboardLoading from 'src/component/DashboardLoading';
import Loader from 'src/component/Loader';
import { toast } from 'react-toastify';

const EditDevice: FC<{ buttonText?: string; defaultValues: DeviceData | null }> = ({ buttonText, defaultValues }) => {
    const { devices, loading: deviceLoading } = useContext(DevicesContext);
    const { towers, loading: towerLoading } = useContext(TowersContext);
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState('');

    const { state } = useLocation<{ deviceId?: string }>();
    const device = devices.find((device) => device.deviceId === state.deviceId);
    const [isPrimary, setIsPrimary] = useState<boolean>(false);

    useEffect(() => {
        setIsPrimary(!!device?.isPrimary);
    }, [device]);

    if (!device) {
        return null;
    }

    const handleEditDevice = async (values: any) => {
        setIsLocalLoading(true);
        const data =
            device.deviceTypeId === DeviceTypesEnum.HCHydroReading ? { ...device, ...values, isPrimary } : { ...device, ...values };
        const res = await updateDevice({ data });
        if (res.status < 300) {
            toast(`updated successfully`, { type: 'success' });
        } else {
            toast(`couldn't update`, { type: 'error' });
        }
        setIsLocalLoading(false);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal('');
    };

    const handleDeleteDevice = ({ id }: { id: string }) => {
        setOpenDeleteModal(id);
    };

    const deleteDevice = () => {
        deleteOneDevice(openDeleteModal);
        setOpenDeleteModal('');
    };

    if (deviceLoading || towerLoading) return <DashboardLoading />;

    return (
        <Box p={2}>
            {isLocalLoading && <Loader />}
            <Formik initialValues={device} validationSchema={editDeviceSchema} onSubmit={handleEditDevice}>
                {({ errors, handleBlur, handleChange, touched, values }) => (
                    <Form>
                        <Box p={2} display="flex" flexDirection="column">
                            <FormLabel label="Device Name" />
                            <TextField
                                name="deviceName"
                                error={Boolean(touched.deviceName && errors.deviceName)}
                                errorText={touched.deviceName && errors.deviceName ? errors.deviceName : ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.deviceName}
                            />
                            <FormLabel label="Tower Name" />
                            <Select
                                options={[{value:"" , label:"--- Select Tower ---"},...towers.map((tower) => ({ value: tower.towerId, label: tower.towerName }))]}
                                name="towerId"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.towerId}
                            />
                            <FormLabel label="Device Type" />
                            <TextField
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={DeviceTypesNameEnum[device.deviceTypeId]}
                                disabled
                            />
                            <FormLabel label="Device Id" />
                            <TextField onBlur={handleBlur} onChange={handleChange} value={device.deviceId} disabled />
                            {device.deviceTypeId === DeviceTypesEnum.HCHydroReading && (
                                <div>
                                    <FormLabel label="Is Primary ?" />
                                    <Switch checked={isPrimary} onChange={(e) => setIsPrimary(e.target.checked)} />
                                </div>
                            )}
                            <FormLabel label="Notes" />
                            <TextField
                                name="notes"
                                error={Boolean(touched.notes && errors.notes)}
                                errorText={touched.notes && errors.notes ? errors.notes : ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.notes}
                                minRows={5}
                                multiline
                                style={{ maxWidth: '50%' }}
                            />
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button text="Edit Device" type="submit" />
                                <Button text="Delete Device" type="button" onClick={() => handleDeleteDevice({ id: device.deviceId })} />
                            </div>
                        </Box>
                    </Form>
                )}
            </Formik>
            <Dialog open={!!openDeleteModal} onClose={handleCloseDeleteModal}>
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete Tower ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        if you delete this tower it will delete all message and devices belong to this tower
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} text="Cancel" secondary autoFocus />
                    <Button onClick={deleteDevice} text="Delete" />
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default EditDevice;
