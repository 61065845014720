// Towers Urls
export const TOWERS = '/towers';
export const CREATE_TOWER = '/create-tower';
// Users Urls
export const USERS = '/users';
export const CREATE_USER = '/create-user';
// Details Urls
export const GATE_DETAILS = '/gate-details';
export const HYDRO_DETAILS = '/hydro-details';
// Devices Urls
export const DEVICES = '/devices';
export const EDIT_DEVICE = '/edit-device';

export const LOGIN = '/login';
export const FORGOT_PASSWORD = "/forgotPassword";
export const DASHBOARD = '/dashboard';

// Alerts
export const ALERTS = '/alerts';

// Profile
export const PROFILE = '/profile';

// Reports 
export const GATE_OPEN_REPORTS = '/gate-open-reports';
