import React, { useState } from 'react';
import { SETTINGS } from 'src/constants/staticPaths';
import { Typography, Box } from '@mui/material';
import DataTable from 'src/component/DataTable';
import { useHistory } from 'react-router';
import { CREATE_USER } from 'src/constants/routes';
import useUsers from 'src/hooks/useUser';
import { User } from 'src/types/user';
import DashboardLoading from 'src/component/DashboardLoading';
import { GridColumns } from '@mui/x-data-grid';
import Fuse from 'fuse.js';

const Users = () => {
    const { users, loading } = useUsers({ all: true });
    const [searchWord, setSearchWord] = useState('');
    const rows = users.map((user: User, index: number) => ({ ...user, index, setting: user.id }));
    const history = useHistory();
    const columns: GridColumns = [
        { field: 'index', headerName: '#' },
        {
            field: 'firstName',
            headerName: 'Name',
            editable: false,
            sortable: true,
            flex: 1,
            renderCell: ({ id }: any) => {
                const user = users.find((u) => u.id === id);
                return <span>{`${user?.firstName} ${user?.lastName}`}</span>;
            },
        },
        {
            field: 'role',
            headerName: 'Role',
            sortable: false,
            flex: 1,
        },
        {
            field: 'setting',
            width: 100,
            headerName: 'Settings',
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ value }: any) => {
                return (
                    <img
                        alt="gate-status"
                        width={20}
                        src={SETTINGS}
                        onClick={() => handleSelectUser({ id: value })}
                        style={{ cursor: 'pointer', margin: 'auto' }}
                    />
                );
            },
        },
    ];
    const handleOnAdd = () => {
        history.push(CREATE_USER);
    };

    const handleSelectUser = ({ id }: { id: number }) => {
        history.push(CREATE_USER, { userId: id });
    };

    const handleSearch = (searchValue: string) => {
        setSearchWord(searchValue);
    };

    const filteredRows = searchWord
        ? new Fuse(rows, {
              keys: ['firstName', 'lastName', 'role'],
          })
              .search(searchWord)
              .map((res) => res.item)
        : rows;
    if (loading) return <DashboardLoading />;

    return (
        <Box p={2}>
            <div className="towers-table">
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>
                    Users Management
                </Typography>
                <DataTable
                    rows={filteredRows}
                    columns={columns}
                    showAdd
                    buttonText="Add new user"
                    onAdd={handleOnAdd}
                    onSearch={handleSearch}
                />
            </div>
        </Box>
    );
};

export default Users;
