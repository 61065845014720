import React, { useContext, useRef, useState } from 'react';
import { Typography, Box } from '@mui/material';
import DataTable from 'src/component/DataTable';
import { gridSpacing } from 'src/constants/styles';
import { FormLabel, AutoComplete, Button } from 'src/component/FormInputs';
import DashboardLoading from 'src/component/DashboardLoading';
import { GridColumns } from '@mui/x-data-grid';
import useReports from 'src/hooks/useReports';
import moment from 'moment';
import { Message } from 'src/types/Message';
import DevicesContext from 'src/contexts/devicesContext';
import TowersContext from 'src/contexts/towersContext';
import { Grid } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { getReports } from 'src/services/reports';
import 'moment-duration-format';

const NotificationTable = () => {
    const csvLink = useRef<any>();

    const { devices } = useContext(DevicesContext);
    const { towers } = useContext(TowersContext);
    const [towerId, setTowerId] = useState('');
    const [allReport, setAllReport] = useState([]);
    const { records, loading } = useReports({ towerId, limit: 100 });
    const { towerName } = towers.find((tower) => tower.towerId === towerId) ?? { towerName: '' };

    const rows = records.map((message: Message, index: number) => {
        const device = devices.find((device) => device.deviceId === message.deviceId);
        const durationHours = message?.data?.To && message?.data?.From ? moment.unix(message?.data?.To).diff(moment.unix(message?.data?.From), "seconds") : ''
        const duration = moment.duration(durationHours, "seconds").format("d[d] hh:mm:ss", {
            trim: false
        })
        return {
            ...message,
            deviceName: device?.deviceName,
            duration: message?.data?.To && message?.data?.From  ? duration : "",
            from: message?.data?.From ? moment.unix(+message?.data?.From).tz('America/Toronto').format('YYYY MM DD HH:mm:ss') : '',
            to: message?.data?.To ? moment.unix(+message?.data?.To).tz('America/Toronto').format('YYYY MM DD HH:mm:ss') : '',
            towerName,
        };
    });
    const columns: GridColumns = [
        {
            field: 'towerName',
            headerName: 'Tower Name',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'deviceName',
            headerName: 'Device Name',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'from',
            headerName: 'From',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'to',
            headerName: 'To',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'duration',
            headerName: 'Duration',
            sortable: false,
            flex: 1,
        },
    ];
    const getAllReport = async (event: any) => {
        const data = await getReports({ towerId });
        const rows = data.map((message: Message, index: number) => {
            const device = devices.find((device) => device.deviceId === message.deviceId);
            const durationHours = message?.data?.To && message?.data?.From ? moment.unix(message?.data?.To).diff(moment.unix(message?.data?.From), "seconds") : ''
            const duration = moment.duration(durationHours, "seconds").format("d[d] hh:mm:ss", {
                trim: false
            })
            return {
                towerName,
                deviceName: device?.deviceName,
                duration: message?.data?.To && message?.data?.From  ? duration : "",
                from: message?.data?.From ? moment.unix(message?.data?.From).tz('America/Toronto').format('YYYY MM DD HH:mm:ss') : '',
                to: message?.data?.To ? moment.unix(message?.data?.To).tz('America/Toronto').format('YYYY MM DD HH:mm:ss') : '',
            };
        });
        setAllReport(rows);
        csvLink.current.link.click();
    };
    if (loading) return <DashboardLoading />;

    return (
        <Box p={2}>
            <Grid container spacing={gridSpacing} justifyContent="space-between">
                <Grid item lg={4} md={12} xs={12} style={{ paddingBottom: '30px' }}>
                    <FormLabel label="Site Name" />
                    <AutoComplete
                        options={towers.map((t) => ({ label: t.towerName, value: t.towerId }))}
                        onChange={({ value }: any) => setTowerId(value)}
                        defaultValue={towerId}
                    />
                </Grid>
                <Grid item lg={4} md={12} xs={12} style={{ paddingBottom: '30px' }}>
                    {towerId && <Button onClick={getAllReport} text="Export" />}
                    <CSVLink data={allReport} filename={towerName} className="hidden" ref={csvLink} target="_blank" />
                </Grid>
            </Grid>
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '30px' }}>
                Gate Opened Duration
            </Typography>

            <div className="towers-table">
                <DataTable rows={rows} columns={columns} showAdd={false} showSearch={false} />
            </div>
        </Box>
    );
};

export default NotificationTable;
