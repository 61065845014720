import axios from 'axios';
import { timeout, baseUrl } from 'src/services/config';

export const getAllNotifications = async ()=> {
  try {
    const { data } = await axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/notifications`);
    return data;
  } catch(err) {
    throw err;
  }
};
