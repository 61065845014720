import { useEffect, useState } from 'react';
import { getUserById, getUsers } from 'src/services/users';
import { User } from 'src/types/user';

const useUsers = ({ userId, all = false }: { userId?: string; all?: boolean }) => {
    const [users, setUsers] = useState<User[]>([]);
    const [user, setUser] = useState<User>();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchAllUsers = async () => {
            const { data } = await getUsers();
            setUsers(data.payload.users);
            setLoading(false);
        };

        const fetchUserById = async ({ userId }: { userId: string }) => {
            const { data } = await getUserById({ userId });
            setUser(data);
            setLoading(false);
        };

        if (!userId && all) {
            fetchAllUsers();
        } else if (userId && !all) {
            fetchUserById({ userId });
        } else {
            setLoading(false);
        }
    }, [userId, all]);

    return {
        loading,
        users,
        user,
    };
};

export default useUsers;
