import React, { FC } from 'react';
import { Paper, Box, Grid } from '@material-ui/core';
import { DeviceInfo, TowerCardHeader, GateStatus } from 'src/component/TowerCard';
import { Typography, Divider } from '@mui/material';
import { MessageByTower } from 'src/types/Message';
import { DeviceTypesEnum } from 'src/types/Device';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { GATE_DETAILS, HYDRO_DETAILS } from 'src/constants/routes';

const useStyles = makeStyles((theme) => ({
    paper: {
        height: '100%',
        backgroundColor: `#FFFFFF !important`,
    },
    icon: {
        color: `${theme.palette.text.secondary} !important`,
        width: 70,
    },
    iconText: {
        color: `${theme.palette.text.secondary} !important`,
    },
    viewText: {
        color: theme.palette.text.hint,
        cursor: 'pointer',
    },
}));

const TowerCard: FC<{ message: MessageByTower }> = ({ message }) => {
    const classes = useStyles();
    const history = useHistory();

    const onClickView = ({ gate = false }: { gate: boolean }) => {
        sessionStorage.setItem(gate ? 'gate-filters' : 'hydro-filters', JSON.stringify({ towerId: message.towerId }));
        history.push({
            pathname: gate ? GATE_DETAILS : HYDRO_DETAILS,
        });
    };

    const onClickDevice = ({ gate = false, deviceId }: { gate: boolean; deviceId?: string }) => {
        sessionStorage.setItem(
            gate ? 'gate-filters' : 'hydro-filters',
            JSON.stringify({ towerId: message.towerId, deviceId: deviceId ?? '' })
        );
        history.push({
            pathname: gate ? GATE_DETAILS : HYDRO_DETAILS,
        });
    };

    return (
        <Paper className={classes.paper}>
            <Box p={0.5} style={{ height: '100%' }}>
                <TowerCardHeader towerName={message.towerName} />
                <Box className="towerCardContent" p={2} style={{ height: '65%', overflowY: 'auto' }}>
                    <Grid container spacing={1} style={{ minHeight: '80px' }}>
                        {message.devices
                            .filter((device) => device.deviceTypeId === DeviceTypesEnum.IOTAGateSensor)
                            .map((device, index) => {
                                const message = device.messages.find((m) => !m.heartBeatOnly);
                                return (
                                    <Grid
                                        item
                                        xs={6}
                                        key={`${index}`}
                                        onClick={() => onClickDevice({ gate: true, deviceId: device.deviceId })}
                                    >
                                        <GateStatus
                                            gateName={device.deviceName ?? ''}
                                            locked={message?.data?.GateStatus !== 'Opened'}
                                            time={message?.time}
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                    <Typography
                        variant="caption"
                        component="h5"
                        align="right"
                        sx={{ fontWeight: 'bold', width: '100%' }}
                        className={classes.viewText}
                        onClick={() => onClickView({ gate: true })}
                    >
                        view details
                    </Typography>
                    <Divider />

                    <div style={{ position: 'relative' }}>
                        <Typography
                            className={classes.iconText}
                            variant="body2"
                            component="h6"
                            align="left"
                            sx={{ fontWeight: 'bold', marginTop: 1, marginBottom: 3 }}
                        >
                            Current billing cycle usage
                        </Typography>
                        <Box justifyContent="space-around" alignItems="center" display="flex" flexDirection="column">
                            {message.devices
                                .filter((device) => device.deviceTypeId === DeviceTypesEnum.HCHydroReading)
                                .map((device, index) => {
                                    return (
                                        <DeviceInfo
                                            key={`${index}`}
                                            value={device.messages[0]?.data?.monthlyConsumption}
                                            deviceName={device.deviceName ?? ''}
                                            showPhaseChangeAlarm={device.messages[0]?.data?.phaseChangeAlarm}
                                        />
                                    );
                                })}
                        </Box>
                    </div>
                    <Divider style={{ marginTop: 16 }} />

                    <div style={{ position: 'relative' }}>
                        <Typography
                            className={classes.iconText}
                            variant="body2"
                            component="h6"
                            align="left"
                            sx={{ fontWeight: 'bold', marginTop: 1, marginBottom: 3 }}
                        >
                           Previous Billing Cycle Usage
                        </Typography>
                        <Box justifyContent="space-around" alignItems="center" display="flex" flexDirection="column">
                            {message.devices
                                .filter((device) => device.deviceTypeId === DeviceTypesEnum.HCHydroReading)
                                .map((device, index) => {
                                    return (
                                        <DeviceInfo
                                            key={`${index}`}
                                            value={device.messages[0]?.data?.lastBillingCycle}
                                            deviceName={device.deviceName ?? ''}
                                            showPhaseChangeAlarm={device.messages[0]?.data?.phaseChangeAlarm}
                                        />
                                    );
                                })}
                        </Box>
                    </div>
                </Box>
                <Typography
                    variant="caption"
                    component="h5"
                    align="right"
                    sx={{ fontWeight: 'bold', width: '100%', position: 'absolute', bottom: 10, right: 18 }}
                    className={classes.viewText}
                    onClick={() => onClickView({ gate: false })}
                >
                    view details
                </Typography>
            </Box>
        </Paper>
        
    );
};

export default TowerCard;
