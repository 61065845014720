import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';

import NavGroup from './NavGroup';
import menuItem from 'src/menu-items';
import AuthContext from 'src/contexts/authContext';

const MenuList = () => {
    const { user } = useContext(AuthContext)
    const navItems = menuItem.items.filter(item => item.role.includes(user?.role ?? "")).map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
