import React, { useContext, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import AuthContext from 'src/contexts/authContext';
import Loading from 'src/component/Loader';
import { useHistory } from 'react-router-dom';

interface AuthGuardProps {
    children?: ReactNode;
}

const PrivateRoute: FC<AuthGuardProps> = ({ children }: AuthGuardProps) => {
    const { loading, isAuthenticated, valid } = useContext(AuthContext);
    useEffect(() => {
        valid();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [children]);

    const history = useHistory();
    if (loading) {
        return <Loading />;
    }

    if (!isAuthenticated) {
        history.push('/login');
        return null;
    }

    return <>{children}</>;
};

export default PrivateRoute;
