import React, { useContext } from 'react';
import type { FC, ReactNode } from 'react';
import AuthContext from 'src/contexts/authContext';
import { useHistory } from "react-router-dom";

interface AuthGuardProps {
  children?: ReactNode;
}

const PublicRoute: FC<AuthGuardProps> = ({ children }: AuthGuardProps) => {
  const {  isAuthenticated } = useContext(AuthContext);
  const history = useHistory()

  if (isAuthenticated) {
    history.push("/");
    return null
  }

  return <>{children}</>;
};

export default PublicRoute;