import React, { FC } from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#F1F1F1',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            borderRadius: 5,
            marginTop: '5px !important',
            boxShadow: '0 4px 6px -2px rgb(0 0 0 / 12%), 0 2px 2px -1px rgb(0 0 0 / 5%)',
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: '#F1F1F1',
        },
    })
);
const CustomizedTooltips: FC<{ title?: any; children: any }> = ({  title, children }) => {
    return (
        <HtmlTooltip arrow  title={title}>
            {children}
        </HtmlTooltip>
    );
};

export default CustomizedTooltips;
