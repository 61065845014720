import React, { useContext, useState } from 'react';
import { SETTINGS } from 'src/constants/staticPaths';
import { Typography, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { deleteTower as deleteOneTower } from 'src/services/towers';
import DeleteIcon from '@mui/icons-material/Delete';
import TowerForm from 'src/views/Towers/createTower';
import DataTable from 'src/component/DataTable';
import { Tower } from 'src/types/Tower';
import TowersContext from 'src/contexts/towersContext';
import DashboardLoading from 'src/component/DashboardLoading';
import Fuse from 'fuse.js';
import { Button } from 'src/component/FormInputs';
import { GridColumns } from '@mui/x-data-grid';

const Towers = () => {
    const [searchWord, setSearchWord] = useState('');
    const { towers, loading: towerLoading } = useContext(TowersContext);
    const rows = towers.map((tower: Tower, index: number) => ({ ...tower, index, setting: tower.id, delete: tower.towerId }));
    const [openModal, setOpenModal] = React.useState(false);
    const [openDeleteModal, setOpenDeleteModal] = React.useState('');
    const [selectedTower, setSelectedTower] = useState<Tower | null>(null);

    const columns: GridColumns = [
        {
            field: 'index',
            headerName: 'ID',
            renderCell: ({ value }: any) => value + 1,
        },
        {
            field: 'towerName',
            headerName: 'Tower name',
            editable: false,
            sortable: true,
            flex: 1,
        },
        {
            field: 'powerSource',
            width: 200,
            headerName: 'Power Source',
            sortable: false,
            headerAlign: 'center',
        },
        {
            field: 'setting',
            width: 100,
            headerName: 'Settings',
            sortable: false,
            headerAlign: 'center',
            renderCell: ({ id }: any) => {
                return (
                    <img
                        alt="gate-status"
                        width={20}
                        src={SETTINGS}
                        onClick={() => handleSelectTower({ id })}
                        style={{ cursor: 'pointer', margin: 'auto' }}
                    />
                );
            },
        },
        {
            field: 'delete',
            width: 100,
            headerName: 'Delete',
            sortable: false,
            headerAlign: 'center',

            renderCell: ({ row }: any) => {
                return <DeleteIcon onClick={() => handleDeletetower({ id: row.delete })} style={{ cursor: 'pointer', margin: 'auto' }} />;
            },
        },
    ];

    const handleClose = () => {
        setOpenModal(false);
        setSelectedTower(null);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal('');
    };
    const handleSearch = (searchValue: string) => {
        setSearchWord(searchValue);
    };
    const handleOpen = () => setOpenModal(true);
    const handleSelectTower = ({ id }: { id: string }) => {
        const tower = rows.find((tower) => tower.id === id) ?? { towerName: '', towerId: '', id: '', companyId: '' };
        setSelectedTower(tower);
        setOpenModal(true);
    };

    const handleDeletetower = ({ id }: { id: string }) => {
        setOpenDeleteModal(id);
    };

    const deleteTower = () => {
        deleteOneTower(openDeleteModal);
        setOpenDeleteModal('');
    };

    const filteredRows = searchWord
        ? new Fuse(rows, {
              keys: ['towerName'],
          })
              .search(searchWord)
              .map((res) => res.item)
        : rows;
    if (towerLoading) return <DashboardLoading />;

    return (
        <Box p={2}>
            <div className="towers-table">
                <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>
                    Towers Management
                </Typography>
                <DataTable
                    rows={filteredRows}
                    columns={columns}
                    showAdd
                    buttonText="Add new tower"
                    onAdd={handleOpen}
                    onSearch={handleSearch}
                />
            </div>

            <Dialog open={openModal} onClose={handleClose} onBackdropClick={handleClose}>
                <DialogTitle id="tower-actions">{'Tower'}</DialogTitle>
                <DialogContent>
                    <TowerForm buttonText={selectedTower ? 'Edit' : 'Create'} defaultValues={selectedTower} />
                </DialogContent>
            </Dialog>

            <Dialog open={!!openDeleteModal} onClose={handleCloseDeleteModal}>
                <DialogTitle id="alert-dialog-title">Are you sure you want to delete Tower ?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        if you delete this tower it will delete all message and devices belong to this tower
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteModal} text="Cancel" secondary autoFocus />
                    <Button onClick={deleteTower} text="Delete" />
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default Towers;
