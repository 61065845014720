import React, { FC, useContext } from 'react';
import { Box, Grid, makeStyles, IconButton, Hidden } from '@material-ui/core';
import { Search as SearchIcon } from '@mui/icons-material';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { TextField, Select } from 'src/component/FormInputs';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import FilterListIcon from '@mui/icons-material/FilterList';
import ProfileSection from './ProfileSection';
import { drawerWidth } from 'src/constants/styles';
import { LOGO_ONLY } from 'src/constants/staticPaths';
import MessagesContext from 'src/contexts/messagesContext';
import { GateStatusEnum } from 'src/types/Device';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1.25),
    },
    menuIcon: {
        fontSize: '1.5rem',
        color: theme.palette.text.secondary,
    },
    text: {
        color: theme.palette.text.secondary,
    },
}));

const Header: FC<{ drawerToggle: () => void }> = (props) => {
    const location = useLocation();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.up('md'));

    const { onSearch, word, onFilter, filter } = useContext(MessagesContext);

    const { drawerToggle } = props;
    const classes = useStyles();

    return (
        <React.Fragment>
            <Box width={!isMobile ? 40 : drawerWidth}>
                <Grid container justifyContent="space-between" alignItems="center">
                    <Hidden smDown>
                        <Grid item>
                            <Box mt={0.5}>
                                <img src={LOGO_ONLY} alt="Logo" style={{ maxHeight: 40, marginRight: 20 }} />
                                <strong className={classes.text}>SIGNUM TOWERS</strong>
                            </Box>
                        </Grid>
                    </Hidden>
                    <Grid item>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={drawerToggle}
                        >
                            <MenuTwoToneIcon className={classes.menuIcon} />
                        </IconButton>
                    </Grid>
                </Grid>
            </Box>
            {location.pathname.includes('dashboard') && (
                <>
                    {!isMobile ? (
                        <Grid container justifyContent="space-evenly">
                            <Grid item xs={6}>
                                <TextField
                                    position="end"
                                    icon={<SearchIcon />}
                                    marginBottom={0}
                                    style={{ color: '#FFFFF', flexBasis: '100%' }}
                                    onChange={(e) => onSearch(e.target.value)}
                                    value={word}
                                    placeholder="Search sites"
                                />
                            </Grid>
                            <Grid item xs={5}>
                                <Select
                                    options={[
                                        { label: '-- select --', value: '' },
                                        { label: 'Gate locked', value: GateStatusEnum.Closed },
                                        { label: 'Gate unlocked', value: GateStatusEnum.Opened },
                                    ]}
                                    onChange={(e) => onFilter(e.target.value)}
                                    value={filter}
                                    marginBottom={0}
                                    placeholder="Filter"
                                    style={{ marginLeft: 10 }}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            <TextField
                                position="end"
                                icon={<SearchIcon />}
                                marginBottom={0}
                                onChange={(e) => onSearch(e.target.value)}
                                value={word}
                                placeholder="Search sites"
                                style={{ minWidth: 250 }}
                            />
                            <Select
                                options={[
                                    { label: '-- select --', value: '' },
                                    { label: 'Gate locked', value: GateStatusEnum.Closed },
                                    { label: 'Gate unlocked', value: GateStatusEnum.Opened },
                                ]}
                                onChange={(e) => onFilter(e.target.value)}
                                value={filter }
                                marginBottom={0}
                                placeholder="Filter"
                                style={{ marginLeft: 10, minWidth: 150 , lineHeight:"unset"}}
                                IconComponent={FilterListIcon}
                            />
                        </>
                    )}
                </>
            )}
            <div className={classes.grow} />

            {/* <Customization /> */}
            <ProfileSection />
        </React.Fragment>
    );
};

export default Header;
