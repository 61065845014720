import React, { FC, useState } from 'react';
import { Grid } from '@material-ui/core';
import { Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    locked: {
        color: '#57A96E',
        minHeight: '12px',
        minWidth: '100%',
        display: 'block',
    },
    unlocked: {
        color: '#DE6969',
        minHeight: '20px',
        minWidth: '100%',
        display: 'block',
    },
    gateName: {
        color: theme.palette.text.secondary,
    },
    iconsSuccess: {
        color: '#57A96E',
        border: '.2vw solid #57A96E',
        borderRadius: '50%',
        padding: 5,
        width: '100% !important',
        height: 'auto !important',
    },
    iconsError: {
        color: '#DE6969',
        border: '.2vw solid #DE6969',
        borderRadius: '50%',
        padding: 5,
        width: '100% !important',
        height: 'auto !important',
    },
}));

const GateStatus: FC<{ gateName: string; locked: boolean; time?: string }> = ({ gateName, locked, time }) => {
    const classes = useStyles();
    const [showTime, setShowTime] = useState(false);
    return (
        <Grid
            container
            onMouseEnter={() => setShowTime(true)}
            onMouseLeave={() => setShowTime(false)}
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
        >
            <Grid item xs={3}>
                {/* <Avatar alt="gate-status" src={locked ? LOCKED : UNLOCKED} sx={{ width: 70, height: 70 }} /> */}
                {locked ? <LockOutlinedIcon className={classes.iconsSuccess} /> : <LockOpenOutlinedIcon className={classes.iconsError} />}
            </Grid>
            <Grid item xs={9}>
                <Typography variant="body1" component="p" className={classes.gateName}>
                    {gateName}
                </Typography>
                <Typography variant="body1" component="p" className={locked ? classes.locked : classes.unlocked}>
                    {locked ? 'LOCKED' : 'UNLOCKED'}
                </Typography>
                <Typography
                    variant="body1"
                    component="span"
                    className={locked ? classes.locked : classes.unlocked}
                    style={{ fontSize: 12 }}
                >
                    {showTime && !locked ? `since ${moment.unix(parseInt(time ?? '')).format('DD/MM/YYYY')}` : ''}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default GateStatus;
