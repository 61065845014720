import React, { FC, useState } from 'react';
import { TextField, Grid } from '@mui/material';
import { DateRange } from '@material-ui/icons';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const DateRangeComp: FC<{ onChange: (newFilter: Record<string, any>) => void; dateRangeFilter: { start?: any; end?: any } }> = ({
    onChange,
    dateRangeFilter,
}) => {
    const [openDatePicker, setOpenDatePicker] = useState(false);

    return (
        <Grid display="flex" style={{ width: '100%' }} justifyContent="space-between">
            <TextField
                inputProps={{
                    style: {
                        padding: 7,
                    },
                }}
                InputProps={{
                    endAdornment: <DateRange />,
                }}
                variant="outlined"
                fullWidth
                value={dateRangeFilter.start ? format(dateRangeFilter.start, 'dd/MM/yyyy') : ''}
                onClick={() => setOpenDatePicker((open) => !open)}
            />

            <TextField
                inputProps={{
                    style: {
                        padding: 7,
                    },
                }}
                InputProps={{
                    endAdornment: <DateRange />,
                }}
                style={{ marginLeft: 10 }}
                variant="outlined"
                fullWidth
                value={dateRangeFilter.end ? format(dateRangeFilter.end, 'dd/MM/yyyy') : ''}
                onClick={() => setOpenDatePicker((open) => !open)}
            />

            <DateRangePicker
                placement="bottomEnd"
                appearance="subtle"
                placeholder="Subtle"
                style={{ visibility: 'hidden', width: '10%', color: 'red' }}
                open={openDatePicker}
                onOk={(dates) => {
                    setOpenDatePicker(false);
                    onChange({ start: moment(dates[0]).valueOf(), end: moment(dates[1]).valueOf() });
                }}
                value={dateRangeFilter.start && dateRangeFilter.end ?[ new Date(dateRangeFilter.start )  ,new Date(dateRangeFilter.end )  ] :[] as any}
            />
        </Grid>
    );
};

export default DateRangeComp;
