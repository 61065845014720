import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import socketIOClient, { Socket } from 'socket.io-client';
import AuthContext from './authContext';
import DevicesContext from './devicesContext';
import MessagesContext from './messagesContext';
import TowersContext from './towersContext';
import { ToastContainer, toast } from 'react-toastify';

const SocketContext = createContext<{
    socket: Socket<any> | undefined;
}>({ socket: undefined });
export const SocketContextProvider: FC = ({ children }) => {
    const [socket, setSocket] = useState<Socket<any> | undefined>();
    const { isAuthenticated } = useContext(AuthContext);
    const { fetchAllMessages } = useContext(MessagesContext);
    const { fetchAllTowers } = useContext(TowersContext);
    const { fetchAllDevices } = useContext(DevicesContext);
    useEffect(() => {
        if (isAuthenticated) {
            const socket = socketIOClient(process.env.REACT_APP_BACK_END_DOMAIN ?? 'http://localhost:8000/', {
                withCredentials: true,
            });
            setSocket(socket);
            socket.on('connection', (data) => {
                console.log('connected');
            });
            socket.on('connect_error', (err) => {
                console.log(`socket_connect_error due to ${err.message}`);
            });

            if (socket) {
                socket.on('messages_update', (data: any) => {
                    toast(`${data.deviceId} send a new message`, { type: 'info' });
                    fetchAllMessages();
                });
                socket.on('towers_update', (data: any) => {
                    console.log(`towers have been updated (new towers): ${data}`);
                    fetchAllMessages();
                    fetchAllTowers();
                });
                socket.on('devices_update', (data: any) => {
                    console.log(`devices have been updated (new devices): ${data}`);
                    fetchAllMessages();
                    fetchAllDevices();
                });
            }
        } else {
            socket?.close();
        }
        return () => {
            socket?.close();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated]);
    return (
        <SocketContext.Provider value={{ socket }}>
            <>
                {children} <ToastContainer />
            </>
        </SocketContext.Provider>
    );
};

export default SocketContext;
