import React, { FC, useContext } from 'react';
import { TableRow, TableCell, TableBody, Table, Typography, TableContainer, TablePagination, Paper } from '@mui/material';

import { Link, makeStyles } from '@material-ui/core';
import ErrorDialog from '../ErrorDialog/ErrorDialog';
import TablePaginationActions from './TablePagination';
import DashboardLoading from 'src/component/DashboardLoading';
import moment from 'moment-timezone';
import useNotifications from 'src/hooks/useNotifications';
import { useHistory } from 'react-router';
import { DeviceTypesEnum } from 'src/types/Device';
import { GATE_DETAILS, HYDRO_DETAILS } from 'src/constants/routes';
import DevicesContext from 'src/contexts/devicesContext';

const useStyles = makeStyles((theme) => ({
    tableBody: {
        height: '100%',
        padding: '0px 40px 0 40px',
        display: 'block !important',
    },
    tableContainer: {
        backgroundColor: `${theme.palette.background.default} !important`,
        color: `${theme.palette.text.primary} !important`,
        position: 'relative',
        borderRadius: `5px !important`,
        marginLeft: 32,
        marginRight: 32,
        width: 'auto !important',
        height: '100%',
        overflow:"hidden !important"
    },
    table: {
        lineHeight: 10,
        '& tr': {
            height: '5vh',
            borderBottom: `2px solid #E9EDF223 `,
        },
        '& td': {
            width: 'max-content',
        },
        height: '85%',
    },
    header: {
        color: `${theme.palette.text.primary} !important`,
        padding: '10px 0px 0 40px',
        fontSize: '25px',
        fontWeight: 700,
        height: '15%',
    },
    cell: {
        color: `${theme.palette.text.primary} !important`,
        '& *': {
            color: `${theme.palette.text.primary} !important`,
        },
        border: `0 !important`,
    },
    cellLink: {
        border: `0 !important`,
    },
}));

const NotificationTable: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const { devices } = useContext(DevicesContext);
    const { notifications, loading, error } = useNotifications();

    const createData = (m: any) => ({
        date: moment(+m.createdAt)
            .tz('America/Toronto')
            .format('MMM DD h:mmA'),
        ...m,
    });

    const rows = notifications?.map((m) => createData(m)) ?? [];

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleClick = ({ towerId, deviceId }: { towerId: string; deviceId: string }) => {
        const device = devices.find((d) => d.deviceId === deviceId);
        history.push({
            pathname: device?.deviceTypeId === DeviceTypesEnum.IOTAGateSensor ? GATE_DETAILS : HYDRO_DETAILS,
            state: {
                towerId,
                deviceId,
            },
        });
    };
    if (loading) return <DashboardLoading />;
    return (
        <>
            {error && <ErrorDialog message={`${error}`} />}
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Typography className={classes.header} sx={{ fontWeight: 'bold' }}>
                    ALERTS
                </Typography>
                <Table className={classes.table}>
                    <TableBody className={classes.tableBody}>
                        {(rowsPerPage > 0 ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : rows).map((row, index) => (
                            <TableRow
                                key={`${row.date} ${row.deviceName} ${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ display: 'flex', width: '100%' }}
                            >
                                <TableCell className={classes.cell}>{row.date}</TableCell>
                                <TableCell className={classes.cell}>{row.towerName}</TableCell>
                                <TableCell className={classes.cell}>{row.deviceName}</TableCell>
                                <TableCell
                                    onClick={() =>
                                        handleClick({
                                            towerId: row.towerId,
                                            deviceId: row.deviceId,
                                        })
                                    }
                                    className={classes.cellLink}
                                >
                                    <Link style={{ cursor: 'pointer', color: '#4190FF' }}>{row.message}</Link>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow style={{ position: 'absolute', bottom: 0, right: 0 }}>
                            <TablePagination
                                className={classes.cell}
                                rowsPerPageOptions={[5,{ label: '-', value: 5 }]}
                                colSpan={3}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                style={{
                                    border: 0,

                                }}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default NotificationTable;
