import axios, { AxiosResponse } from 'axios';
import { timeout, baseUrl } from 'src/services/config';

export const login = async (data: { email: string; password: string }): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).post(`${baseUrl}/auth/login`, data);
};

export const logout = async (): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).delete(`${baseUrl}/auth/logout`);
};

export const valid = async (): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/auth/valid`);
};
