import React from 'react';

const Battery = ({ level }: { level: string }) => {
    return (
        <svg width="26" height="17" xmlns="http://www.w3.org/2000/svg" fill="none" style={{ margin: 'auto' }}>
            <path
                fill="#364861"
                d="m20.1504,16.4006l-17.77501,0c-0.5238,0 -1.02615,-0.2081 -1.39653,-0.5785c-0.37039,-0.3704 -0.57847,-0.8727 -0.57847,-1.3965l0,-11.85001c0,-0.52381 0.20808,-1.02616 0.57847,-1.39654c0.37038,-0.37038 0.87273,-0.57846 1.39653,-0.57846l17.77501,0c0.5238,0 1.0261,0.20808 1.3965,0.57846c0.3704,0.37038 0.5785,0.87273 0.5785,1.39654l0,0.9875l0.9875,0c0.5238,0 1.0261,0.20808 1.3965,0.57846c0.3704,0.37038 0.5785,0.87273 0.5785,1.39654l0,5.92501c0,0.5238 -0.2081,1.0261 -0.5785,1.3965c-0.3704,0.3704 -0.8727,0.5785 -1.3965,0.5785l-0.9875,0l0,0.9875c0,0.5238 -0.2081,1.0261 -0.5785,1.3965c-0.3704,0.3704 -0.8727,0.5785 -1.3965,0.5785zm-17.77501,-13.82501l0,11.85001l17.77501,0l0,-2.9625l2.9625,0l0,-5.92501l-2.9625,0l0,-2.9625l-17.77501,0z"
            />
            {level === 'FULL' && <rect height="9" width="14" y="4" x="4"  fill="#364861" />}

            {level === 'MEDIUM' && <path  fill="#FFCB14" d="m13.02853,5l0,7.52381l-9.02853,0l0,-7.52381l9.02853,0z" />}
            {level === 'LOW' && <rect height="8" width="3" y="4.6" x="4" fill="#ff0000" />}
        </svg>
    );
};

export default Battery;
