import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: 0,
        left: 0,
        width: '100%',
        height: "100vh",
        zIndex: 99999,
        background:"#00000023",
        overflow: "hidden"
    },
}));


const Loader = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
        </div>
    );
};

export default Loader;
