import moment from 'moment';
import { Device, DeviceTypesEnum } from './types/Device';
import type { Filter } from './types/filter';
import type { Tower } from './types/Tower';

export const getAllMonthsForYear = (year: number = moment().year()) => {
    return moment.months().slice(0, moment().year() === year ? moment.months().indexOf(moment().format('MMMM')) + 1 : 12);
};

export const getFromTimeByFilter = (filters: Filter) => {
    return filters.start && filters.end
        ? filters.start
        : filters.year
        ? moment(`1/1/${filters.year}`, 'D/M/YYYY').startOf('month').valueOf()
        : moment(`1/${filters.month}/${moment().year()}`, 'D/M/YYYY').startOf('month').valueOf();
};

export const getToTimeByFilter = (filters: Filter) => {
    return filters.start && filters.end
        ? filters.end
        : filters.year
        ? moment(`31/12/${filters.year}`, 'D/M/YYYY').endOf('month').valueOf()
        : moment(`1/${filters.month}/${moment().year()}`, 'D/M/YYYY').endOf('month').valueOf();
};

export const getStartTimeByFilter = (filters: Filter) => {
    return !filters.year && !filters.month ? filters.start ?? moment().subtract(30, 'd').valueOf() : undefined;
};

export const getEndTimeByFilter = (filters: Filter) => {
    return !filters.year && !filters.month ? filters.end ?? moment().valueOf() : undefined;
};

export const getDevicesTower = ({
    devices,
    towers,
    filters,
    deviceTypeId,
}: {
    devices: Device[];
    towers: Tower[];
    filters: Filter;
    deviceTypeId: DeviceTypesEnum;
}) => {
    const tower = towers.find((t) => t.towerId === filters.towerId);
    return devices.filter((d) => d.towerId === tower?.towerId && d.deviceTypeId === deviceTypeId);
};


export const getMaxNumber = (data: { date: string; n: number }[]) => Math.max(...data.map((_) => _.n));