import React, { createContext, useReducer, useEffect } from 'react';
import { login, logout, valid } from 'src/services/auth';
import axios from 'axios';

import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import LoaderBar  from 'src/component/Loader';

interface AuthState {
    user: User | null;
    loading: boolean;
    isAuthenticated: boolean;
}

interface AuthContextValue {
    login: any;
    logout: any;
    loading: boolean;
    valid: any;
    isAuthenticated: boolean;
    user: User | null;
}

interface AuthProviderProps {
    children: ReactNode;
}

type AuthAction = {
    type: 'AUTH';
    payload: {
        user: User;
    };
};

type LogoutAction = {
    type: 'LOGOUT';
};
type LoadingAction = {
    type: 'LOADING';
    payload: {
        loading: boolean;
    };
};

type Action = LogoutAction | AuthAction | LoadingAction;

const initialAuthState: AuthState = {
    loading: true,
    isAuthenticated: false,
    user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'AUTH': {
            return { ...state, isAuthenticated: true, loading: false, user: action.payload.user };
        }
        case 'LOADING': {
            return { ...state, loading: action.payload.loading };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                loading: false,
                user: null,
            };
        }
        default: {
            return { ...state };
        }
    }
};

const AuthContext = createContext<AuthContextValue>({
    ...initialAuthState,
    login: () => Promise.resolve(false),
    logout: () => Promise.resolve(),
    valid: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }: { children: ReactNode }) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);

    useEffect(() => {
        const checkSession = async () => {
            await validSession();
        };
        checkSession();
    }, []);

    const loginUser = async ({ email, password }: { email: string; password: string }) => {
        try {
            dispatch({
                type: 'LOADING',
                payload: {
                    loading: true,
                },
            });
            const {
                data: { payload },
            } = await login({ email, password });
            dispatch({
                type: 'AUTH',
                payload: {
                    user: payload.user,
                },
            });

            return { success: true };
        } catch (error) {
            if (axios.isAxiosError(error)) {
                dispatch({
                    type: 'LOADING',
                    payload: {
                        loading: false,
                    },
                });
                return { success: false, message: error.response?.data.errors };
            }
        }
    };

    const logoutUser = async () => {
        try {
            await logout();
            dispatch({ type: 'LOGOUT' });
        } catch (error) {
            console.log(error);
        }
    };

    const validSession = async () => {
        try {
            const {
                data: { payload },
            } = await valid();
            dispatch({
                type: 'AUTH',
                payload: {
                    user: payload.user,
                },
            });
        } catch (error) {
            dispatch({ type: 'LOGOUT' });
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login: loginUser,
                logout: logoutUser,
                valid: validSession,
            }}
        >
            {state.loading && <LoaderBar />}
            <>{children}</>
        </AuthContext.Provider>
    );
};

export default AuthContext;
