import axios from 'axios';
import { timeout, baseUrl } from 'src/services/config';
import { NotificationsConfig } from 'src/types/NotificationConfig';

export const getAllNotificationsConfig = async () => {
    return await axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/notifications/config`);
};

export const updateNotificationsConfig = async (notificationConfig: NotificationsConfig) => {
    return axios
        .create({ timeout, withCredentials: true })
        .put(`${baseUrl}/notifications/config/${notificationConfig.notificationConfigId}`, {
            ...notificationConfig,
            modifiedAt: Date.now().toString(),
        });
};
