import React, { FC } from 'react';
import { OutlinedInput, InputAdornment, OutlinedInputProps } from '@mui/material';

interface TextFieldCompProps extends OutlinedInputProps {
    position?: 'start' | 'end';
    icon?: any;
    errorText?: string;
    marginBottom?: number
}
const TextFieldComp: FC<TextFieldCompProps> = ({ position = 'start', errorText, icon , marginBottom =15 , ...params }) => {
    const options = {
        ...params,
        ...(position === 'start'
            ? { endAdornment: <InputAdornment position={position}>{icon}</InputAdornment> }
            : { startAdornment: <InputAdornment position={position}>{icon}</InputAdornment> }),
    };
    return (
        <div className='sg-input' style={{ marginBottom , display:"flex" , flexDirection:"column" }}>
            <OutlinedInput {...options} className="FormTextField-root" />
            <span style={{color:"#eb3626"}}>{errorText}</span>
        </div>
    );
};

export default TextFieldComp;
