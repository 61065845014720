import React from 'react'

const HydroIcon = () => {
  return (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5ZM12 23.5C18.3513 23.5 23.5 18.3513 23.5 12C23.5 5.64873 18.3513 0.5 12 0.5C5.64873 0.5 0.5 5.64873 0.5 12C0.5 18.3513 5.64873 23.5 12 23.5ZM4.99996 8.93821C4.99996 8.42005 5.42001 8 5.93817 8H17.7284C18.2465 8 18.6666 8.42005 18.6666 8.93822V11.8451C18.6666 12.3633 18.2465 12.7833 17.7284 12.7833H5.93817C5.42001 12.7833 4.99996 12.3633 4.99996 11.8451V8.93821ZM8.73471 10.6755H6.99996V9.66094H8.73471V10.6755ZM9.77549 10.6755H11.5102V9.66094H9.77549V10.6755ZM14.2859 10.6755H12.5512V9.66094H14.2859V10.6755ZM15.3267 10.6755H17.0614V9.66094H15.3267V10.6755ZM12.6263 13.305L10.518 16.3717L10.0841 17.0029H10.85H12.3724L10.5208 19.626L11.1792 20.0906L13.4792 16.8323L13.9276 16.1971H13.15H11.6159L13.2903 13.7616L12.6263 13.305Z"
              fill="currentColor"
          />
      </svg>
  );
}

export default HydroIcon