import React, { useRef, useState, FC } from 'react';
import { BarChart, Bar, Label, XAxis, Cell, YAxis, LabelList, CartesianGrid, ResponsiveContainer } from 'recharts';

const graphHeight = 500;

const BarGraph: FC<{ data: any[]; unit: string; label?: string; ticks?: number[] }> = ({ data, unit = 'kw', label, ticks }) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const graphRef = useRef<any>(null);

    const renderCustomizedLabel = (props: any) => {
        const { x, y, width: w, index } = props;
        const values = data.at(index);
        const graphWidth = graphRef.current.current.clientWidth;
        const width = 150;
        const height = 88;
        const dataMarginTop = 10;
        const dateMarginBottom = 30;
        const unitTextSpace = 45;
        const lineSpacePadding = 5;
        const arrowHeight = 20;
        const yLocation = y < height + arrowHeight ? height + arrowHeight : y;
        const xLocation = x + width / 2 + w / 2 > graphWidth ? graphWidth - width / 2 : x;
        if (index !== activeIndex) return null;
        return (
            <g>
                <rect x={xLocation - width / 2 + w / 2} y={yLocation - height - w} width={width} height={height} rx="5" fill="white" />
                <text
                    x={xLocation + w / 2}
                    y={yLocation - height + dataMarginTop}
                    fill="rgba(65, 144, 255, 1)"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={40}
                >
                    {values?.n}
                </text>
                <text
                    x={xLocation + w / 2 + unitTextSpace}
                    y={yLocation - height + dataMarginTop}
                    fill="rgba(65, 144, 255, 1)"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={13}
                >
                    {unit}
                </text>
                <line
                    x1={xLocation - width / 2 + w / 2 + w}
                    y1={yLocation - height / 2 - lineSpacePadding}
                    x2={xLocation + width / 2 + w / 2 - w}
                    y2={yLocation - height / 2 - lineSpacePadding}
                    stroke="#A6A4A4"
                />

                <text x={xLocation + w / 2} y={yLocation - dateMarginBottom} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                    {values?.date}
                </text>
                <path
                    transform={`translate(${x - w / 2}, ${yLocation - arrowHeight})`}
                    d={`M12.4999 11.25L0.375557 0.75L24.6243 0.75L12.4999 11.25Z`}
                    fill="white"
                />
            </g>
        );
    };

    return (
        <ResponsiveContainer width="100%" height={graphHeight} ref={graphRef}>
            <BarChart data={data} barSize={15} onMouseLeave={(data, index) => setActiveIndex(-1)}>
                <CartesianGrid horizontal vertical={false} stroke="rgba(167, 165, 165, 0.2)" />
                <XAxis dataKey="date" angle={-45} textAnchor="end" interval={data.length >= 60 ? 5 : 0} height={150} />
                <YAxis interval={0} ticks={ticks} stroke="rgba(166, 164, 164, 1)">
                    <Label value={label} position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
                </YAxis>
                <defs>
                    <linearGradient id="coloractive" x1="0" y1="0%" x2="0" y2="100%" spreadMethod="reflect">
                        <stop offset="0" stopColor="rgba(65, 144, 255, 1)" />
                        <stop offset="1" stopColor="rgba(54, 72, 97, 0.75)" />
                    </linearGradient>
                </defs>
                <defs>
                    <linearGradient id="colorInActive" x1="0" y1="0%" x2="0" y2="100%" spreadMethod="reflect">
                        <stop offset="0" stopColor="rgba(54, 72, 97, 1)" />
                        <stop offset="1" stopColor="rgba(54, 72, 97, .75)" />
                    </linearGradient>
                </defs>
                <Bar dataKey="n" onMouseEnter={(data, index) => setActiveIndex(index)}>
                    {data.map((entry, index) => (
                        <Cell key={index} cursor="pointer" fill={index === activeIndex ? 'url(#coloractive)' : 'url(#colorInActive)'} />
                    ))}
                    <LabelList dataKey="n" content={renderCustomizedLabel} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default BarGraph;
