import { useEffect, useState, useContext } from 'react';
import { Notification } from 'src/types/Notification';
import { getAllNotifications } from 'src/services/notifications';
import SocketContext from 'src/contexts/SocketContext';
import { toast } from 'react-toastify';

const useNotifications = () => {
    const { socket } = useContext(SocketContext);

    const [notifications, setNotifications] = useState<Notification[] | undefined>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        const getAllNotificationsAsync = async () => {
            setLoading(true);
            try {
                const data = await getAllNotifications();
                setNotifications(data);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };
        getAllNotificationsAsync();
        if (socket)
            socket.on('notifications_update', (data: any) => {
                getAllNotificationsAsync();
                toast(data, { type: 'info' });
            });

        return () => {
            if (socket) {
                socket.removeAllListeners('notifications_update');
            }
        };
    }, [socket]);

    return {
        loading,
        notifications,
        error,
    };
};

export default useNotifications;
