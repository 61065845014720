import React, { useContext } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthProvider } from 'src/contexts/authContext';
import theme from '../themes';
import Routes from '../Routes';
import { TowersProvider } from 'src/contexts/towersContext';
import { DevicesProvider } from 'src/contexts/devicesContext';
import { MessagesProvider } from 'src/contexts/messagesContext';
import { SocketContextProvider } from 'src/contexts/SocketContext';
import themeContext from 'src/contexts/themeContext';

const App = () => {
    const { theme: currentTheme } = useContext(themeContext);

    return (
        <AuthProvider>
            <TowersProvider>
                <DevicesProvider>
                    <MessagesProvider>
                        <SocketContextProvider>
                            <CssBaseline />
                            <ThemeProvider theme={theme(currentTheme)}>
                                <Routes />
                            </ThemeProvider>
                        </SocketContextProvider>
                    </MessagesProvider>
                </DevicesProvider>
            </TowersProvider>
        </AuthProvider>
    );
};

export default App;
