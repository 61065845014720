import React, { createContext, useReducer, useEffect, FC, ReactNode, useContext, useState } from 'react';
import { getAllRecentMessages } from 'src/services/messages';
import type { MessageByTower } from 'src/types/Message';
import AuthContext from './authContext';

interface MessagesState {
    messages: MessageByTower[];
    loading: boolean;
}

interface MessagesContextValue {
    loading: boolean;
    messages: MessageByTower[];
    fetchAllMessages: any;
    onSearch: any;
    onFilter: any;
    word?: string;
    filter?: string;
}

interface MessagesProviderProps {
    children: ReactNode;
}

type MessagesAction = {
    type: 'GET_DEVICES';
    payload: {
        messages: MessageByTower[];
    };
};

type Action = MessagesAction;

const initialMessagesState: MessagesState = {
    loading: true,
    messages: [],
};

const reducer = (state: MessagesState, action: Action): MessagesState => {
    switch (action.type) {
        case 'GET_DEVICES': {
            return { ...state, loading: false, messages: action.payload.messages };
        }
        default: {
            return { ...state };
        }
    }
};

const MessagesContext = createContext<MessagesContextValue>({
    ...initialMessagesState,
    fetchAllMessages: () => Promise.resolve(),
    onSearch: (word: string) => Promise.resolve(),
    onFilter: (filter: string) => Promise.resolve(),
});

export const MessagesProvider: FC<MessagesProviderProps> = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, initialMessagesState);
    const [word, setWord] = useState('');
    const [filter, setFilter] = useState('');

    const fetchAllMessages = async () => {
        try {
            const { data } = await getAllRecentMessages();
            dispatch({
                type: 'GET_DEVICES',
                payload: {
                    messages: data,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const onSearch = (word: string) => {
        setWord(word);
    };

    const onFilter = (filter: string) => {
        setFilter(filter);
    };

    useEffect(() => {
        if (isAuthenticated) {
            fetchAllMessages();
        }
    }, [isAuthenticated]);

    return (
        <MessagesContext.Provider
            value={{
                ...state,
                onSearch,
                onFilter,
                filter,
                word,
                fetchAllMessages,
            }}
        >
            {children}
        </MessagesContext.Provider>
    );
};

export default MessagesContext;
