import { useEffect, useState } from 'react';
import { getReports } from 'src/services/reports';
import { Message } from 'src/types/Message';

const useReports = ({ towerId, limit }: { towerId: string; limit?: number }) => {
    const [records, setRecords] = useState<Message[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        const getAllRecords = async () => {
            setLoading(true);
            try {
                const data = await getReports({ towerId, limit });
                setRecords(data);
            } catch (error: any) {
                setError(error);
            }
            setLoading(false);
        };
        if (towerId) {
            getAllRecords();
        } else {
            setLoading(false);
        }
    }, [towerId, limit]);

    return {
        loading,
        records,
        error,
    };
};

export default useReports;
