import axios, { AxiosResponse } from 'axios';
import { timeout, baseUrl } from 'src/services/config';

export const getAllRecentMessages = async (): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/messages/recent`);
};

export const getAllMessages = async ({ from, to, deviceId }: { from: string; to: string; deviceId: string }): Promise<AxiosResponse> => {
    return axios
        .create({ timeout, withCredentials: true })
        .get(`${baseUrl}/messages?${new URLSearchParams({ from, to, deviceId }).toString()}`);
};
