import React, { FC } from 'react';
import { Button, ButtonProps } from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = (secondary: boolean) =>
    makeStyles((theme) => ({
        button: {
            backgroundColor: `${secondary ? theme.palette.text.primary : theme.palette.background.default} !important`,
            color: `${secondary ? theme.palette.background.default : theme.palette.text.primary} !important`,
        },
    }));
interface ButtonCompProps extends ButtonProps {
    text?: string;
    secondary?: boolean;
}
const ButtonComp: FC<ButtonCompProps> = ({ text, secondary = false, ...props }) => {
    const classes = useStyles(secondary)();

    return (
        <Button variant="contained" className={`${classes.button} form-input-button`} {...props} disableElevation>
            {text}
        </Button>
    );
};

export default ButtonComp;
