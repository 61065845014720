import React, { FC, useState } from 'react';
import { Box } from '@mui/material';
import { Button, FormLabel, TextField } from 'src/component/FormInputs';
import { Form, Formik } from 'formik';
import { createTowerSchema } from 'src/validations/towers';
import { newTower, Tower } from 'src/types/Tower';
import Loader from 'src/component/Loader';
import { createTower, updateTower } from 'src/services/towers';
import { toast } from 'react-toastify';

const CreateTower: FC<{ buttonText?: string; defaultValues: Tower | null }> = ({ buttonText, defaultValues }) => {
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

    const handleCreateTower = async (values: any) => {
        setIsLocalLoading(true);
        const res = await createTower({
            data: { towerName: values.towerName, notes: values.notes, powerSource: values.powerSource },
        }).catch((err) => {
            toast(err.response.data, { type: 'error' });
        });
        if (res && res.status < 300) {
            toast(`created successfully`, { type: 'success' });
        }
        setIsLocalLoading(false);
    };

    const handleEditTower = async (values: any) => {
        setIsLocalLoading(true);
        const res = await updateTower({
            data: { ...defaultValues, towerName: values.towerName, notes: values.notes, powerSource: values.powerSource },
        });
        if (res.status < 300) {
            toast(`updated successfully`, { type: 'success' });
        } else {
            toast(`couldn't update`, { type: 'error' });
        }
        setIsLocalLoading(false);
    };
    return (
        <Box p={2}>
            {isLocalLoading && <Loader />}

            <Formik
                initialValues={defaultValues ?? newTower}
                validationSchema={createTowerSchema}
                onSubmit={defaultValues ? handleEditTower : handleCreateTower}
            >
                {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                    <Form>
                        <Box p={2} display="flex" flexDirection="column">
                            <FormLabel label="Tower Name" />
                            <TextField
                                name="towerName"
                                error={Boolean(touched.towerName && errors.towerName)}
                                errorText={touched.towerName && errors.towerName ? errors.towerName : ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.towerName}
                            />
                            <FormLabel label="Power Source" />
                            <TextField
                                name="powerSource"
                                error={Boolean(touched.powerSource && errors.powerSource)}
                                errorText={touched.powerSource && errors.powerSource ? errors.powerSource : ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.powerSource}
                            />
                            <FormLabel label="Note" />
                            <TextField
                                name="notes"
                                error={Boolean(touched.notes && errors.notes)}
                                errorText={touched.notes && errors.notes ? errors.notes : ''}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.notes}
                                minRows={5}
                                multiline
                            />
                            <Button text={buttonText} type="submit" />
                        </Box>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CreateTower;
