import React, { FC } from 'react';
import { Box, Grid } from '@material-ui/core';
import { gridSpacing } from 'src/constants/styles';
import { Typography, Button, Stack } from '@mui/material';
import { FormLabel, AutoComplete, Select, DateRange } from 'src/component/FormInputs';
import { Tower } from 'src/types/Tower';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Device } from 'src/types/Device';
import LockIcon from 'src/assets/svg/LockIcon';
import { getAllMonthsForYear } from 'src/utilities';
import { Filter } from 'src/types/filter';



const useStyles = makeStyles((theme) => ({
    deviceButtonSelected: {
        '&:hover': {
            borderWidth: 2,
            color: theme.palette.primary.main,
        },
    },
    deviceButtonNotSelected: {
        color: '#a6a4a4 !important',
        border: '2px solid #a6a4a4 !important',
        '&:hover': {
            border: '2px solid #a6a4a4 !important',
        },
    },
}));

const GateDetailsForm: FC<{
    towerDevices: Device[];
    onChange: (newFilter: Record<string, any>) => void;
    showFilter?: boolean;
    filters: Filter;
    towers: Tower[];
    handleSelectDevice: (deviceId: string) => void;
    selectedDevices: string[];
}> = ({ towerDevices, onChange, showFilter = false, filters, towers, handleSelectDevice, selectedDevices }) => {
    const classes = useStyles();
    const months = getAllMonthsForYear();
    const diff = +moment().diff('2022-01-01', 'years') + 1;
    const years = Array.from(Array(diff).keys()).map((i: number) => i + moment().year());


    return (
        <>
            <Grid container spacing={gridSpacing} justifyContent="space-between">
                <Grid item lg={4} md={12} xs={12}>
                    <FormLabel label="Site Name" />
                    <AutoComplete
                        options={towers.map((t) => ({ label: t.towerName, value: t.towerId }))}
                        onChange={({ value }: any) => onChange({ towerId: value })}
                        defaultValue={filters.towerId}
                    />
                </Grid>
                {showFilter && (
                    <Grid item lg={6} md={6} xs={12}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item lg={3} md={6} xs={12}>
                                <FormLabel label="Monthly Usage" />
                                <Select
                                    options={[
                                        { label: 'select month', value: undefined },
                                        ...months.map((m, i) => ({ label: m, value: i + 1 })),
                                    ]}
                                    onChange={(event: any) => onChange({ month: event.target.value })}
                                    value={filters.month}
                                />
                            </Grid>
                            <Grid item lg={3} md={6} xs={12}>
                                <FormLabel label="Annual Usage" />
                                <Select
                                    options={[{ label: 'select year', value: undefined }, ...years.map((m, i) => ({ label: m, value: m }))]}
                                    value={filters.year}
                                    onChange={(event: any) => onChange({ year: event.target.value })}
                                />
                            </Grid>
                            <Grid item lg={5} md={12} xs={12}>
                                <FormLabel label="Date Range" />
                                <DateRange onChange={onChange} dateRangeFilter={{ start: filters.start, end: filters.end }} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
            {showFilter && (
                <Box mt={2}>
                    <FormLabel label="Select Gate Type" />
                    <Stack direction="row" spacing={2}>
                        {towerDevices.map((device) => (
                            <Button
                                className={selectedDevices.some(d => d === device.deviceId)? classes.deviceButtonSelected : classes.deviceButtonNotSelected}
                                variant="outlined"
                                startIcon={<LockIcon />}
                                size="large"
                                onClick={(_event: any) => handleSelectDevice(device.deviceId)}
                                key={device.deviceId}
                            >
                                <Typography variant="body2" style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
                                    {device.deviceName}
                                </Typography>
                            </Button>
                        ))}
                    </Stack>
                </Box>
            )}
        </>
    );
};

export default GateDetailsForm;
