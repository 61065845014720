export interface TowerData {
    towerName: string;
    notes?: string;
    powerSource?: string;
}

export interface Tower extends TowerData {
    id: string;
    towerId: string;
    companyId: string;
}

export const newTower: TowerData = {
    towerName: '',
    notes: '',
};
