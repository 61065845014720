import { RoleName } from 'src/types/Role';

export interface UserData {
    firstName: string;
    lastName: string;
    email: string;
    role: RoleName;
    companyId: string;
    password: string;
    confirmPassword: string;
    id: string;
}

export interface User extends UserData {
    userId: string;
}

export const newUser: UserData = {
    firstName: '',
    lastName: '',
    email: '',
    role: RoleName.USER,
    companyId: '',
    password: '',
    confirmPassword: '',
    id: '',
};
