export enum DeviceTypesEnum {
    HCHydroReading = '621fd3de37f03a48fae110ab',
    IOTAGateSensor = '621fd11ca4da5e3db44b3a95',
}

export enum DeviceTypesNameEnum {
    '621fd3de37f03a48fae110ab' = 'Hydro Device' as any,
    '621fd11ca4da5e3db44b3a95' ='Gate Device' as any,
}

export enum GateStatusEnum {
    Opened = 'Opened',
    Closed = 'Closed',
}

export interface DeviceData {
    deviceName?: string;
    towerId?: string;
}

export interface Device {
    id?: string;
    deviceId: string;
    deviceTypeId: DeviceTypesEnum;
    deviceName?: string;
    towerId?: string;
    notes?: string;
    isPrimary?: boolean;
}
