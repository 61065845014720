import React, { createContext, useReducer, useEffect, FC, ReactNode, useContext } from 'react';
import { getAllTowers } from 'src/services/towers';
import type { Tower } from 'src/types/Tower';
import AuthContext from './authContext';

interface TowersState {
    towers: Tower[];
    loading: boolean;
}

interface TowersContextValue {
    loading: boolean;
    towers: Tower[];
    fetchAllTowers: any;
}

interface TowersProviderProps {
    children: ReactNode;
}

type TowersAction = {
    type: 'GET_TOWERS';
    payload: {
        towers: Tower[];
    };
};

type Action = TowersAction;

const initialTowersState: TowersState = {
    loading: true,
    towers: [],
};

const reducer = (state: TowersState, action: Action): TowersState => {
    switch (action.type) {
        case 'GET_TOWERS': {
            return { ...state, loading: false, towers: action.payload.towers };
        }
        default: {
            return { ...state };
        }
    }
};

const TowersContext = createContext<TowersContextValue>({
    ...initialTowersState,
    fetchAllTowers: () => Promise.resolve(),
});

export const TowersProvider: FC<TowersProviderProps> = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const [state, dispatch] = useReducer(reducer, initialTowersState);
    const fetchAllTowers = async () => {
        try {
            const { data } = await getAllTowers();
            dispatch({
                type: 'GET_TOWERS',
                payload: {
                    towers: data,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (isAuthenticated) {
            fetchAllTowers();
        }
    }, [isAuthenticated]);

    return (
        <TowersContext.Provider
            value={{
                ...state,
                fetchAllTowers,
            }}
        >
            {children}
        </TowersContext.Provider>
    );
};

export default TowersContext;
