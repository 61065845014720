import React, { useContext, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, FormLabel, TextField } from 'src/component/FormInputs';
import { Form, Formik } from 'formik';
import { editUserSchema } from 'src/validations/users';
import { updateUser } from 'src/services/users';
import { toast } from 'react-toastify';
import Loader from 'src/component/Loader';
import AuthContext from 'src/contexts/authContext';

const Profile = () => {
    const { user, loading } = useContext(AuthContext);
    const [isLocalLoading, setIsLocalLoading] = useState<boolean>(false);

    const handleEditProfile = async (data: any) => {
        setIsLocalLoading(true);
        try {
            const res = await updateUser({ data });
            if (res?.status < 300) {
                toast(`user created successfully`, { type: 'success' });
            } else {
                toast(`couldn't create`, { type: 'error' });
            }
        } catch (error: any) {
            toast(error.response.data.message, { type: 'error' });
        }

        setIsLocalLoading(false);
    };
    return (
        <Box p={2}>
            {isLocalLoading && <Loader />}
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '15px' }}>
                Your Profile
            </Typography>

            {!loading && user && (
                <Formik initialValues={user} validationSchema={editUserSchema} onSubmit={handleEditProfile}>
                    {({ errors, handleBlur, handleChange, touched, values }) => (
                        <Form>
                            <Box p={2} display="flex" flexDirection="column">
                                <FormLabel label="First Name" />
                                <TextField
                                    name="firstName"
                                    error={Boolean(touched.firstName && errors.firstName)}
                                    errorText={touched.firstName && errors.firstName ? errors.firstName : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.firstName}
                                />
                                <FormLabel label="Last Name" />
                                <TextField
                                    name="lastName"
                                    error={Boolean(touched.lastName && errors.lastName)}
                                    errorText={touched.lastName && errors.lastName ? errors.lastName : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.lastName}
                                />
                                <FormLabel label="Email" />
                                <TextField
                                    name="email"
                                    error={Boolean(touched.email && errors.email)}
                                    errorText={touched.email && errors.email ? errors.email : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.email}
                                />
                                <FormLabel label="Password" />
                                <TextField
                                    name="password"
                                    error={Boolean(touched.password && errors.password)}
                                    errorText={touched.password && errors.password ? errors.password : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    type="password"
                                />
                                <FormLabel label="Confirm Password" />
                                <TextField
                                    name="confirmPassword"
                                    error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                                    errorText={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ''}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.confirmPassword}
                                    type="password"
                                />

                                <Button text={'Update'} type="submit" />
                            </Box>
                        </Form>
                    )}
                </Formik>
            )}
        </Box>
    );
};

export default Profile;
