import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './layout/App';
import * as serviceWorker from './serviceWorker';
import { ThemeContextProvider } from 'src/contexts/themeContext';

import 'react-loading-skeleton/dist/skeleton.css'
import "swiper/css";
import "swiper/css/navigation";
import 'src/assets/scss/style.scss';
import "rsuite/dist/rsuite.min.css";
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <BrowserRouter>
        <ThemeContextProvider>
            <App />
        </ThemeContextProvider>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
