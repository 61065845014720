import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import MainLayout from 'src/layout/MainLayout';
import MinimalLayout from 'src/layout/MinimalLayout';
import {
    DASHBOARD,
    USERS,
    LOGIN,
    CREATE_USER,
    GATE_DETAILS,
    DEVICES,
    EDIT_DEVICE,
    TOWERS,
    HYDRO_DETAILS,
    CREATE_TOWER,
    ALERTS,
    PROFILE,
    FORGOT_PASSWORD,
    GATE_OPEN_REPORTS,
} from 'src/constants/routes';
import PrivateRoute from './component/ProtectedRoute';
import AdminRoute from './component/AdminRoute';
import Towers from 'src/views/Towers';
import AuthLogin from './views/Login';
import Dashboard from './views/Dashboard';
import GateDetails from './views/GateDetails';
import HydroDetails from './views/HydroDetails';
import CreateUser from './views/Users/createUsers';
import Devices from './views/Devices';
import EditDevice from './views/Devices/editDevice';
import CreateTower from './views/Towers/createTower';
import GateOpenReports from './views/Reports/gate-open';
import Users from './views/Users';
import Alerts from './views/Alerts';
import Profile from './views/Profile';
import ForgetPassword from './views/ForgetPassword/ForgetPassword';

const Routes = () => {
    const location = useLocation();

    return (
        <Switch>
            <Redirect exact from="/" to={DASHBOARD} />
            <Route path={[]}>
                <MinimalLayout>
                    <Switch location={location} key={location.pathname}></Switch>
                </MinimalLayout>
            </Route>
            <Route
                path={[
                    DASHBOARD,
                    GATE_DETAILS,
                    DEVICES,
                    TOWERS,
                    HYDRO_DETAILS,
                    ALERTS,
                    EDIT_DEVICE,
                    CREATE_USER,
                    CREATE_TOWER,
                    USERS,
                    PROFILE,
                    GATE_OPEN_REPORTS,
                ]}
            >
                <PrivateRoute>
                    <MainLayout>
                        <Switch location={location} key={location.pathname}>
                            <Route path={DASHBOARD} component={Dashboard} />
                            <Route path={GATE_DETAILS} component={GateDetails} />
                            <Route path={HYDRO_DETAILS} component={HydroDetails} />
                            <Route path={DEVICES} component={Devices} />
                            <Route path={EDIT_DEVICE} component={EditDevice} />
                            <Route path={TOWERS} component={Towers} />
                            <Route path={ALERTS} component={Alerts} />
                            <Route path={PROFILE} component={Profile} />
                            <Route path={GATE_OPEN_REPORTS} component={GateOpenReports} />
                            <AdminRoute>
                                <Route path={USERS} component={Users} />
                                <Route path={CREATE_USER} component={CreateUser} />
                                <Route path={CREATE_TOWER} component={CreateTower} />
                            </AdminRoute>
                        </Switch>
                    </MainLayout>
                </PrivateRoute>
            </Route>
            <Route path={[LOGIN, FORGOT_PASSWORD]}>
                <MinimalLayout>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path={LOGIN} component={AuthLogin} />
                        <Route path={FORGOT_PASSWORD} component={ForgetPassword} />
                    </Switch>
                </MinimalLayout>
            </Route>
        </Switch>
    );
};

export default Routes;
