import React, { createContext, useReducer, useEffect, FC, ReactNode, useContext, useCallback } from 'react';
import { getAllDevices } from 'src/services/devices';
import { Device, DeviceTypesEnum } from 'src/types/Device';
import AuthContext from './authContext';

interface DevicesState {
    devices: Device[];
    loading: boolean;
}

interface DevicesContextValue {
    loading: boolean;
    devices: Device[];
    fetchAllDevices: any;
    getDefaultDeviceGateByTowerId: any;
    getDefaultDeviceHydroByTowerId: any;
}

interface DevicesProviderProps {
    children: ReactNode;
}

type DevicesAction = {
    type: 'GET_DEVICES';
    payload: {
        devices: Device[];
    };
};

type Action = DevicesAction;

const initialDevicesState: DevicesState = {
    loading: true,
    devices: [],
};

const reducer = (state: DevicesState, action: Action): DevicesState => {
    switch (action.type) {
        case 'GET_DEVICES': {
            return { ...state, loading: false, devices: action.payload.devices };
        }
        default: {
            return { ...state };
        }
    }
};

const DevicesContext = createContext<DevicesContextValue>({
    ...initialDevicesState,
    fetchAllDevices: () => Promise.resolve(),
    getDefaultDeviceGateByTowerId: (towerId: string) => {},
    getDefaultDeviceHydroByTowerId: (towerId: string) => {},
});

export const DevicesProvider: FC<DevicesProviderProps> = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);

    const [state, dispatch] = useReducer(reducer, initialDevicesState);
    const fetchAllDevices = async () => {
        try {
            const { data } = await getAllDevices();
            dispatch({
                type: 'GET_DEVICES',
                payload: {
                    devices: data,
                },
            });
        } catch (error) {
            console.log(error);
        }
    };

    const getDefaultDeviceGateByTowerId = useCallback(
        (towerId: string) => {
            const towerDevices: Device[] = state.devices.filter(
                (d) => d.towerId === towerId && d.deviceTypeId === DeviceTypesEnum.IOTAGateSensor
            );
            const towerDevice = towerDevices.find((d) => d.deviceName?.toLowerCase().includes('vehicle')) ?? towerDevices[0];
            return towerDevice?.deviceId;
        },
        [state.devices]
    );

    const getDefaultDeviceHydroByTowerId = useCallback(
        (towerId: string) => {
            const towerDevices: Device[] = state.devices.filter(
                (d) => d.towerId === towerId && d.deviceTypeId === DeviceTypesEnum.HCHydroReading
            );
            const towerDevice = towerDevices.find((d) => d.isPrimary) ?? towerDevices[0];
            return towerDevice?.deviceId;
        },
        [state.devices]
    );

    useEffect(() => {
        if (isAuthenticated) {
            fetchAllDevices();
        }
    }, [isAuthenticated]);

    return (
        <DevicesContext.Provider
            value={{
                ...state,
                fetchAllDevices,
                getDefaultDeviceGateByTowerId,
                getDefaultDeviceHydroByTowerId,
            }}
        >
            {children}
        </DevicesContext.Provider>
    );
};

export default DevicesContext;
