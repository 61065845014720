import axios, { AxiosResponse } from 'axios';
import { timeout, baseUrl } from 'src/services/config';
import _ from 'lodash';

export const getUserById = async ({ userId }: { userId: string }): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/users/${userId}`);
};

export const getUsers = async (): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).get(`${baseUrl}/users`);
};

export const updateUser = async ({ data }: { data: any }): Promise<AxiosResponse> => {
    const finalData = _.omit(data, ['id', 'userId']);
    return axios.create({ timeout, withCredentials: true }).put(`${baseUrl}/users/${data.userId}`, finalData);
};

export const createUser = async ({ data }: { data: any }): Promise<AxiosResponse> => {
    return axios.create({ timeout, withCredentials: true }).post(`${baseUrl}/users`, data);
};

